.mail-template {
    padding: 2rem 0;
}

.mail-template-buttons {
    display: flex;
    gap: 15px;
    padding: 1rem 0;
    justify-content: space-between;
}

.add-content-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.mail-template-save-btn {
    /* margin-left: auto; */
    display: block;
    margin-top: 0.9rem;
    line-height: 0;
}

.editor {
    margin-bottom: 10px;
}

.p-editor-container .p-editor-content .ql-editor {
    background-color: inherit !important;
    color: inherit !important;
}

.dark .p-editor-container .p-editor-toolbar {
    background-color: #222 !important;
}

.dark .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background-color: #222 !important;
}

.dark .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #999 !important;
}
.mail-template-dialog{
    width: 35vw;
}
.mail-template-dialog table{
    width: 100%;
  }