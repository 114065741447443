.information-col {
    display: flex;
    gap: 20px;
}

.p-selectbutton .p-button {
    border: 1px solid #ced4da;
}

.lable-role {
    padding: 0.5rem;
    background-color: #e0e0e0;
    border-radius: var(--btn_border_radius);
    text-transform: CAPITALIZE;
    color: rgb(17, 17, 17);
}
/* .selectBtn {
    background-color: green;
    border: green;
    width: 100%;
}

.selectBtn:hover {
    background-color: green;
    border: green;
} */

.non-selectBtn {
    background-color: rgb(150 150 150);
    border: rgb(150 150 150);
    width: 100%;
    height: 100% !important;
}
.selectBtn{
    height: 100% !important;
}
.non-selectBtn span,
.selectBtn span
{
    flex: inherit !important;
    word-break: break-all;
    text-align: left !important;
}
.kpi-points-note {
    max-width: 30rem;
    font-size: 14.6px;

}

.searched-kpis-box {
    display: flex;
    gap: 0.4rem;
    border: 1px solid #cac1c1;
    padding: 0.5rem;
    border-radius: var(--btn_border_radius);
    flex-wrap: wrap;
}
.searched-kpis-box .non-selectBtn{
    background: var(--extralightblue);
    border: 1px solid transparent !important;
    /* color: var(--lightblue-highlightbtn); */
}
.add-kpi-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.search_input {
    width: 100% !important;
}

.kpi-mapp-btn {
    position: relative;
    max-width: 350px;
    width: 100%;
    display: inline-block;
}
.kpi-mapp-btn button{
    padding-right: 35px;
    width: 100%;
    height: 100%;
    text-align: left;
    word-break: break-all;
}
.kpi-mapp-btn button span{
    flex: inherit;
}
.cross-kpi-mapp {
    cursor: pointer;
    position: absolute;
    right: 11px;
    top: 14px;
    color: #fff;
}

.kpiMap_table{
    border-collapse:collapse;
}
.kpiMap_table th, .kpiMap_table td{
    padding: 3px 12px 3px 0px;
    text-align: left;
}
.kpiMap_table .p-slider.p-slider-horizontal{
    margin-top: 10px;
}
.kpi-input-label {
    display: flex;
    justify-content: space-between;
}

.kpi-input-label>div:nth-of-type(2) {
    display: flex;
    gap: 12rem;
}
@media only screen and (max-width:768px){
    .KpiMap_tab{
        width: 95% !important;
    }
    .add_new_kpi{
        width: 25rem !important;
    }
}
@media only screen and (max-width:575px) {

    .all-kpi-points .p-button .p-button-label {
        font-size: 12px;
    }

    .btn-sliders {
        flex-direction: column;
    }

    .selectBtn,
    .slider,
    .non-selectBtn,
    .slider .p-slider-horizontal {
        width: 100% !important;
    }

    .kpimapSec .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
        font-size: 15px;
    }
    .add_kpi_btn{
        font-size: 14px;
        justify-content: center;
        padding: 0.6rem !important;
        margin: 0px !important;
    }
    .kpi-mapp-btn{
        max-width: none;
        width: 250px;
    }
}
@media only screen and (max-width:490px){
    .kpi-mapp-btn{
        max-width: none;
        width: 200px;
    }
}