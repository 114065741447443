.editDeleteButtonTemplate {
  display: flex;
  gap: .5rem;
  justify-content: center;
}

.kpiColumn {
  margin-left: 1rem;
}

.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column:hover .p-sortable-column-icon {
  color: white !important;
}

.p-datatable .p-sortable-column .p-highlight .p-sortable-column-icon {
  color: white;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: white;
}

.activeIcon {
  background-color: green;
}

.inactiveicon {
  background-color: #830200;
}

.endToolbar {
  display: flex;
  gap: 1rem;
}

/* .editDelButton {
  padding: 0.55rem 1.45rem;
  font-size: 0.8rem;
  background-color: #830200;
  border: none;
} */
.editDelButton {
  padding: 7px 12px !important;
  font-size: 0.8rem !important;
  background-color: #830200;
  border: none !important;
  height: auto;
}
.EditBtn span:nth-last-of-type(1),
.delBtn  span:nth-last-of-type(1){
  display: none;
}
.editDelButton:hover{
  background-color: #830200;
}

.dialogHeader {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}
.dialogHeader h4{
  margin-bottom: 0;
}
.dialogHeader > p {
  font-size: 1rem;
  font-weight: lighter;
}
.field {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.kpiInput {
  width: 100%;
}

.p-dialog .p-dialog-content {
  gap: 0.6rem;
  display: flex;
  flex-direction: column;
}

.kpi-save-button {
  float: right;
  padding: 0.55rem 1.25rem;
  border: none;
}

.p-paginator {
  background-color: transparent;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 1px 1px 1px 1px;
}

.p-datatable .p-paginator-bottom {
  border-radius: 0.7rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.actionColumn{
  width: 115px;
  text-align: center;
}
.actionColumn .p-column-header-content{
  justify-content: center;
}

/* .kpiColumn .p-tag {
  display: initial;
} */
.kpiColumn .p-tag {
  display: flex;
  margin: auto;
}
/* .del_modal .p-dialog-content, .del_modal .p-dialog-footer{
  padding-top: 0.85rem !important;
} */
.performance-view-header h4{
  font-size: 18px;
  margin-bottom: 0;
}
/* custom-textarea.............  */
.custom-textarea{
  overflow-y: auto !important;
    max-height: 150px !important;
    height: 100px !important;
    padding: 5px 0.8rem;
}
.kpi_tabile_res table tr td{
  vertical-align: top;
}
.kpi_tabile_res table tr td textarea{
  width: 255px !important;
  min-height: 30px !important;
  margin-top: 8px;
  padding: .5rem .75rem !important;
  resize: vertical;
}

.kpi_tabile_res table tr td input{
  width: 50px !important;
}
.kpi_tabile_res table tr td .p-checkbox{
  display: flex !important;
  margin: 0px auto;
}
.kpi_tabile_res .status-button-container .status-button .p-button{
  padding: 7px !important;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}
.kpi_tabile_res table tr td{
  padding: 0.5rem !important;
}
.kpi_tabile_res .p-datatable>.p-datatable-wrapper{
  /* max-width: calc(100vw - 300px);
  overflow: auto; */
}
.kpi_dropdown{
  max-width: 215px !important;
}
.kpi_dropdown .p-dropdown-items{
  max-width: 215px !important;
}
.add_row_btn{
  width: 25px;
  height: 25px;
  border: 1px solid var(--blue-highlightbtn);
  background-color: var(--blue-highlightbtn);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.main_kpi_table{
  border-radius: var(--card_border_radius);
}
.bengali_btn_style{
  flex-wrap: wrap;
    width: 210px;
    align-items: center;
}
.skill_table_btns{
  width: 176px;
  gap: 5px;
}
@media screen and (min-width:1366px) {
  .kpi_tabile_res table tr td textarea{
    width: 330px !important;
  }
}
@media screen and (max-width: 1199px){
  .kpi_tabile_res .p-datatable>.p-datatable-wrapper{
    /* max-width:calc(100vw - 45px) !important; */
  }
  .kpi_tabile_res .p-datatable-table{
    min-width: 1000px;
  }
  .kpi_tabile_res table tr td textarea{
    min-width: 300px !important;
  }
}
@media only screen and (max-width: 991px) {
  
  .editDeleteButtonTemplate {
    gap: 0.5rem;
  }
  .performance-view-header h4{
    font-size: 17px;
  }
  .kpi-save-button{
    padding: 0.55rem 1.25rem !important;
  }
  .kpi_tabile_res .p-datatable-table{
    width: 900px;
  }
}

@media only screen and (max-width: 767px) {
  .dialog{
    width: 70% !important;
  }
}
@media only screen and (max-width: 575px) {
  /* .kpiColumn .editDeleteButtonTemplate {
    flex-direction: column;
  } */
  .kpiColumn .editDelButton {
    margin-right: 0 !important;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    font-size: 13px;
    line-height: 21px;
  }
  .p-datatable-table {
    width: 120%;
    overflow-x: auto;
  }
  .p-dialog-title hr {
    margin: 0 !important;
    width: 109%;
  }
  .kpiColumn .editDeleteButtonTemplate span.p-button-label{
    flex: 1 1 auto !important;
  }
  .example-custom-input {
    padding: 0 10px !important;
}
.dialog{
  width: 90% !important;
}
}

@media only screen and (max-width: 480px) {
  .kpiInput {
    width: 100%;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail 
{
  font-size: 15px !important;
}
.p-toast-top-right
{
  right: auto !important;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message{
  margin-left: 0px;
}
.p-dialog .p-dialog-content
{
  padding: 0 1rem 2rem 1rem;
}
/* .performance-view-header h4{
  text-align: center;
} */
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th.kpiColumn {
  font-size:12px;
}
}
.kpiMapTable .p-datatable-wrapper .p-datatable-tbody > tr.p-row-odd td{
  background-color: #fff !important;
}
.user_MapTable .p-datatable-wrapper .p-datatable-tbody > tr.p-row-odd td{
  background-color: #fff !important;
}