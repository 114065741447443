.all-performance-view {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    /* max-height: calc(100vh - 320px);
    overflow-y: auto; */
}

.performance-view-header {
    /*display: flex;
    justify-content: space-between;
     background-color: blueviolet;
    color: white;
    align-items: center; */
    padding: 0.6rem 1rem;
    border-radius: 5px 5px 0 0;
}
.loop_accordian{
    height: fit-content;
}

.performance-view-kpis {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.8rem 0.8rem 0.8rem 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.093) 0px 1px 4px;
    width: 100%;
}

.performance-view-ratings-div,
.performance-view-reviews-div {
    display: flex;
    gap: 0.5rem;
    border: 1px solid #c5c3c3;
    padding: 0.5rem;
    border-radius: var(--card_border_radius);
}

.performance-view-ratings-div>p:nth-of-type(1),
.performance-view-reviews-div>p:nth-of-type(1) {
    font-weight: bold;
    font-size: 1rem;
}

.performance-view-reviews-div>p:nth-of-type(2) {
    word-break: break-all;
}

.comment {
    font-weight: normal;
    font-size: 0.9rem;
}

.performance-view-kpi-name {
    font-weight: bold;
    font-size: 1.1rem;
    word-break: break-all;
}

.per-head-container {
    display: flex;
    flex-direction: column;
    /* padding: 0 1.8rem;
    padding-right: 0; */
    padding: 0;
}
.p-progressbar{
    border-radius: 0 !important;
}

.per-head-all-elements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 5.8px; */
}
.perViewHead .p-accordion-header-link svg{
    top: 10px !important;
    width: 30px !important;
    height: 30px !important;
    left: 14px;
}
.perViewHead  .p-accordion-content{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.p-accordion-header.per-head.perViewHead a{
    background: #D1E5FD !important;
    color: var(--blue-highlightbtn-hover) !important;
}
.p-accordion-header.p-highlight.per-head.perViewHead a{
    background: var(--blue-highlightbtn-hover) !important;
    color: #fff !important;
}
.per-head.perViewHead.p-highlight .p-accordion-header-link svg{
    fill: #fff !important;
}

@media only screen and (max-width: 480px) {
    .per-head-all-elements {
        display: flex;
        flex-wrap: wrap;
    }
}

.per-head-desc {
    font-weight: 400;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.p-progressbar{
    height: 6.5px !important;
}
.p-progressbar-label>div{
    font-size: 0.9rem !important;
}
.p-progressbar .p-progressbar-value{
    background: var(--green-400) !important;
}

.all-sub-kpi-details {
    margin-bottom: 13.5px;
}

.content {
    padding: 7.5px 18px;
}


.sub-kpi-name {
    text-transform: capitalize;
    font-size: inherit;
    font-weight: 400;
}

.radio-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
    margin-top: 8px;
    font-size: 14px;
}
.radioSingle {
    padding: 0.5rem 0.8rem;
    background: var(--extralightblue);
    border-radius: var(--btn_border_radius);
    display: flex;
    text-align: left;
    justify-content: start;
    min-width: 130px;
}

.all-buttons {
    padding: 10px;
    display: flex;
    justify-content: center;
}
.all-buttons button{
    border: none !important;
    box-shadow: 0 0 2px #666;
}

.comment-input {
    /* width: 22rem; */
    flex-grow: 1;
    overflow-y: auto !important;
    height: 100% !important;
    max-height: 150px !important;
}

.reason-input {
    width: 100%;
}

.arr-btn-view {
    background-color: #E4F1FF;
    color: black;
    border: 1px solid #E4F1FF;
}

.arr-btn-view:hover {
    background-color: #E4F1FF;
    color: black;
}

.performance-view-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.6rem 0;
    justify-content: end;
    background: none !important;
}

.cancel_btn {
    color: #fff !important;
}

.accordionIndex {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    /* height: 70px; */
    position: static;
}

.per-sub-elements {
    display: flex;
    gap: 15px;
    align-items: center;
}

.performance-view-inputs {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.average-rating-for-admin {
    padding: 1rem 0;
}

.rating-date .p-button {
    border-width: 0px;
    color: #6e6c6c !important;
}

.score-box {
    width: fit-content;
    /* color: #fff; */
    padding: 0.7rem 2rem !important;
    border-radius: 0.9rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.score-box span{
    font-size: 20px !important;
    font-weight: 600 !important;
}
.score-box .score_text{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #EBF5FF;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .score-box .line {
    width: 1px;
    height: 25px;
    background-color: #fff;
    display: inline-block;
} */

.average-rating-for-admin h3 {
    font-size: 1.5rem;
}

.average-rating-for-admin h3 span {
    font-size: 1.7rem;
    font-weight: 500;
}

.info-icon {
    margin: 0 6px;
    color: #6e6c6c;
    cursor: pointer;
}

.reason-change-dialog .p-inputtextarea {
    height: 45px !important;
    max-height: 60px;
}



@media only screen and (max-width:767px) {
    .performance-view-buttons {
        justify-content: center;
        padding: 0.7rem 0rem;
    }

    /* .performance-view-header {
        justify-content: center;
        text-align: center;
    } */

    .performance-view-header,
    .all-performance-view {
        flex-direction: column;
    }

    .performance-view-kpis,
    .comment-input {
        width: 100% !important;
    }

    .performance-view-header h4,
    .performance-view-kpi-name {
        font-size: 17px !important;
        margin-bottom: 0.7rem;
    }

    .performance-view-header {
        padding: 1rem;
    }
}

@media screen and (max-width:480px) {
    .report-chart-table .p-datatable-thead>tr>th {
        padding: 0.5rem 1rem !important;
        font-size: 1.2rem !important;
    }

    .report-chart-table .p-datatable-thead>tr>th .p-column-header-content {
        font-size: 0.8rem !important;
    }
}

/*----------------------------Slider styles ---------------------------------------*/