.home-page{
    display: flex;
    height: 100vh;
     overflow: hidden;
}

.loader{
    flex: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}