.project-toolbar {
    position: relative;
}

.endToolbarProject {
    position: absolute;
    right: 1rem;
    bottom: 0rem;
    display: flex;
    gap: 0.5rem;
}


.project-toolbar-button {
    color: gray;
    background-color: transparent;
    border: none;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;
}

.project-toolbar-button:hover,.selected {
    color: black;
    background-color: #d6d6d6;
}

.p-datatable>.p-datatable-wrapper{
    max-height: calc(100vh - 260px );
    overflow: auto;
}
@media only screen and (max-width:575px){
    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last{
        min-width: 2rem;
        height: 2rem;
    }
}