.emp-name-col {
    display: flex;
    gap: 1rem;
    align-items: center;
    text-transform: capitalize;
}

.emp-name-col>span:nth-of-type(1) {
    cursor: pointer;
}
.rightArrowIcon_toggle {
    display: flex;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background: #ededed;
    border-radius: 50%;
}

/* .sidebar-open .performance-table .p-datatable-wrapper {
    width: calc(100vw - 311px);
}

.sidebar-close .performance-table .p-datatable-wrapper {
    width: calc(100vw - 145px);
} */
.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
    padding: 0.5rem 1.25rem;
}
.dashbord-card-cont{
    padding: 1rem 0;
}
.performance-report-card-cont .apexcharts-toolbar{
    top: -60px !important;
    right: 10px !important;
}
.performance-report-card-cont .p-card-body{
    padding: 0!important;
}
.performance-report-card-cont .p-card-title{
    background-color: var(--extralightblue);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-left: 1rem;
    padding-right: 1rem;
}

@media screen and (min-width:768px) {
    .perreportToolbar .p-buttonset .p-button:nth-of-type(1):not(.p-highlight),
    .perreportToolbar .p-buttonset .p-button:nth-of-type(2):not(.p-highlight),
    .perreportToolbar .p-buttonset .p-button:nth-of-type(3):not(.p-highlight),
    .perreportToolbar .p-buttonset .p-button:nth-of-type(4):not(.p-highlight){
        border-right: none !important;
    }
}

@media screen and (max-width:767px) {
    .perreportToolbar .p-buttonset .p-button{
        margin-right: 10px !important;
        margin-bottom: 10px !important;
        border-radius: var(--btn_border_radius) !important;
    }
    .perreportToolbar .p-buttonset .p-button:not(.p-highlight){
        border-color: #ced4da !important;
    }
    
}
@media screen and (max-width:680px) {
    .p-report-toolbar .p-toolbar-group-right {
        width: 100%;
    }
    .p-report-toolbar .date-input {
        justify-content: left;
    }
    .p-report-toolbar .report-start-tool {
        flex-direction: unset;
    }
}
