/* .progress-key{
    font-size: 23px !important;
     font-weight: 700 !important;
} */
.user_goal_dialog  .p-dialog-content{
    padding: 0 !important;
}
.employee-name-container{
    width: 50%; 
    /* margin-bottom: 12px; */
}

.goal-name-container{
    width: 50%;
     /* margin-bottom: 12px; */
}

.progress-value{
    display: flex !important;
    /* font-size: 25px !important; */
    gap:2px !important;
}

.score-key{
    /* font-size: 25px !important; 
    font-weight: 700 !important; */
}

.score-value{
     display: flex !important;
     /* font-size: 23px !important; */
     gap:2px !important;
}

.employee-with-name{
    font-weight: bold;
    font-size: 16px;
    margin-right: 6px;
}

.goal-with-name{
    font-weight: bold;
    font-size: 16px;
    margin-right: 6px;
    white-space: nowrap;
}

.goal-with-description{
    font-weight: bold !important;
    font-size: 16px !important;
}

.goal-steps{
    font-weight: bold !important;
     font-size: 16px !important;
    margin-right: 6px !important;
}

.step-name-container{
    padding: 0px 8px;
    display: flex;
    gap: 5px;
}

.step-description-and-score-container{
    display: flex;
    justify-content: space-between;
    gap: 2px;
    align-items: center;
}
.score-delete-button {
    background-color: transparent !important;
    border: none !important;
    margin-left: 5px !important;
    color: var(--bs-danger) !important;
}
.value-input-style{
    width: 6px;
    height: 28px;
    font-size: 14px;
    margin-right: 5px;
}

.score-input-style{
    width: 6px;
    height: 28px;
    font-size: 14px;
}