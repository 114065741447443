.error-container{
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 22px;
}

.err-header{
    font-size: 22px;
}