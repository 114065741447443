.assign-to-employees-header {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

.assign-to-employees-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px 0px;
}
.assign-to-employees-table  > tr th {
  background-color: var(--blue-highlightbtn) !important;
  color: var(--bs-white) !important;
}
.assign-to-employees-table  > tr th:first-child {
  border-top-left-radius: 7px;
}
.assign-to-employees-table  > tr th:nth-last-of-type(1) {
  border-top-right-radius: 7px;
}
.light .assign-to-employees-table  > tr:nth-of-type(n) td {
  background-color: #f2f2f2 !important;
}

@media only screen and (max-width: 575px) {
  .assign-to-employees-table {
    width: 140%;
  }
}
