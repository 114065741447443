.unhandled-err {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2.9rem 0;
}

.unhandled-err>img {
    width: 20%;
}

.unhandled-err>p {
    width: 30%;
    text-align: center;
}

.unhandled-err-btn {
    display: flex;
    gap: 20px;
}

.page-not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.9rem 0;
}

.page-not-found>img{
    width: 20%;
}