.cron-input {
    padding: 0.6rem 1rem;
    border: 1px solid #c9c5c5;
    border-radius: 4.9px;
    width: 50%;
    font-size: 1.2rem;
    text-align: center;
}

.cron-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
}

.cron-container .p-button {
    padding: 0.75rem 1rem;
}

.react-js-cron>div,
.react-js-cron-field {
    flex-wrap: wrap;
    justify-content: center;
}

.cron-btn-container {
    display: flex;
    gap: 10px;
}