.login-btn {
    display: flex;
    justify-content: center;
}

.login-loading-bar {
    position: absolute;
    left: 74.8%;
    top: 47%;
    z-index: 5000000;
    transform: translate(-50%, -50%);
}

.login-input {
    width: 100%;
    padding: 1rem 0.9rem;
    font-size: 1rem;
    border: 1px solid #b7b1b1;
    border-radius: var(--btn_border_radius);
    outline: none;
}

.password-box {
    position: relative;
}

.password-box>span {
    position: absolute;
    top: 18.5px;
    right: 19.3px;
    cursor: pointer;
}