 
:root {
  --body-fontsize: 0.9rem !important;
  --input_height_40: 35px !important; 
  --card_border_radius: 10px !important;
  --btn_border_radius: 6px !important;

  /* --blue-highlightbtn: #0355A0 !important; 
  --blue-highlightbtn-hover: #0D72CF !important;*/
  --primary-color:#2E6198 !important;
  --highlight-text-color:  #4075AE !important;
  --blue-highlightbtn: #2E6198 !important;
  --blue-highlightbtn-hover: #4075AE !important;
  --lightblue-highlightbtn: #5BADF9 !important;
  --lightblue-highlightbtn-hover: #3593EA !important;
  --extralightblue: #DCEEFF !important;
  --extralightblueOne:#E9F1FF !important;
  --extralight_card_bg: #F5FAFF !important;
  --extradark_bg: #071426 !important;

  --violet-highlightbtn: rgb(85, 31, 179) !important;
  --violet-highlightbtn-hover: #7b45d9 !important;
  --lightviolet-highlightbtn: rgb(85, 31, 179) !important;
  --lightviolet-highlightbtn-hover: #7b45d9 !important;

  --green-highlightbtn: #407053 !important;
  --green-highlightbtn-hover: #5CA177 !important;
  --lightgreen-highlightbtn: #407053 !important;
  --lightgreen-highlightbtn-hover: #5CA177 !important;

  --maroon-highlightbtn: #652524 !important;
  --maroon-highlightbtn-hover: #9B4444 !important;
  --lightmaroon-highlightbtn: #9B4444 !important;
  --lightmaroon-highlightbtn-hover: #652524 !important;
}


body,
html {
  font-size: var(--body-fontsize);
}


/*Theme blue*/
.blue .p-selectbutton .p-button.p-highlight,
.blue .p-button:not(.p-selectbutton .p-button):not(.editDelButton),
.blue .p-button-icon-only,
.blue .p-checkbox .p-checkbox-box.p-highlight,
.blue .p-selectbutton .p-button.p-highlight {
  background: var(--blue-highlightbtn);
  border-color: var(--blue-highlightbtn);
  color: #ffffff;
}

.blue .p-selectbutton .p-button.p-highlight:hover,
.blue .p-button:hover,
.blue .p-button:enabled:hover,
.blue .p-button:not(button):not(a):not(.p-disabled):hover,
.blue .p-checkbox .p-checkbox-box.p-highlight:hover,
.blue .p-selectbutton .p-button.p-highlight:hover {
  background: var(--blue-highlightbtn-hover);
  border-color: var(--blue-highlightbtn-hover);
  color: #ffffff;
}


/*individual component force color change*/
.blue .react-datepicker__day--selected,
.blue .react-datepicker__day--in-range,
.blue .p-selectbutton.p-buttonset .p-button.p-highlight {
  background-color: var(--blue-highlightbtn);
  color: #fff;
  border-color: transparent
}

.blue .react-datepicker__day--selected:hover,
.blue .react-datepicker__day--in-range:hover,
.blue .p-selectbutton.p-buttonset .p-button.p-highlight:hover {
  background-color: var(--blue-highlightbtn-hover);
  color: #fff;
}

.blue .react-datepicker__day--keyboard-selected {
  background-color: var(--blue-highlightbtn-hover);
}
.blue .react-datepicker__header {
  background-color: var(--blue-highlightbtn);
  border-bottom: 1px solid var(--blue-highlightbtn);
}
.blue .per-head-desc.bg-primary-100 {
  background-color: var(--primary-100) !important;
}
.blue .p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--blue-highlightbtn) !important;
  background: var(--blue-highlightbtn) !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: var(--blue-highlightbtn) !important;
}
.blue .p-button.p-component.p-highlight{
  background: var(--blue-highlightbtn) !important;
}
.blue .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--blue-highlightbtn) !important;
}
.blue .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--blue-highlightbtn) !important;
  background: var(--blue-highlightbtn) !important;
}
.bg_primary{
  background: var(--blue-highlightbtn) !important;
}
.bg_primaryLight{
  background: #ebf5ff !important;
}
.p-selectbutton .p-button.p-highlight, .p-selectbutton .p-button.p-highlight:hover{
  background: var(--blue-highlightbtn) !important;
  border-color: var(--blue-highlightbtn) !important;
}

/*------------------------------------------------------------------------------*/



/*Theme violet*/

.violet .p-selectbutton .p-button.p-highlight,
.violet .p-button:not(.p-selectbutton .p-button):not(.editDelButton),
.violet .p-button-icon-only,
.violet .p-checkbox .p-checkbox-box.p-highlight,
.violet .p-selectbutton .p-button.p-highlight {
  background: var(--violet-highlightbtn);
  border-color: var(--violet-highlightbtn);
  color: #ffffff;
}

.violet .p-selectbutton .p-button.p-highlight:hover,
.violet .p-button:hover,
.violet .p-button:enabled:hover,
.violet .p-button:not(button):not(a):not(.p-disabled):hover,
.violet .p-checkbox .p-checkbox-box.p-highlight:hover,
.violet .p-selectbutton .p-button.p-highlight:hover {
  background: var(--violet-highlightbtn-hover);
  border-color: var(--violet-highlightbtn-hover);
  color: #ffffff;
}


/*individual component force color change*/
.violet .react-datepicker__day--selected,
.violet .react-datepicker__day--in-range,
.violet .p-selectbutton.p-buttonset .p-button.p-highlight {
  background-color: var(--violet-highlightbtn);
  color: #fff;
  border-color: transparent
}

.violet .react-datepicker__day--selected:hover,
.violet .react-datepicker__day--in-range:hover,
.violet .p-selectbutton.p-buttonset .p-button.p-highlight:hover {
  background-color: var(--violet-highlightbtn-hover);
  color: #fff;
}
.violet .react-datepicker__header {
  background-color: var(--violet-highlightbtn);
  border-bottom: 1px solid var(--violet-highlightbtn);
}
.violet .react-datepicker__day--keyboard-selected {
  background-color: #999;
}

.violet .per-head-desc.bg-primary-100 {
  background-color: var(--primary-100) !important;
}

/*------------------------------------------------------------------------------*/

/*Theme green*/
.green .p-selectbutton .p-button.p-highlight,
.green .p-button:not(.p-selectbutton .p-button):not(.editDelButton),
.green .p-button-icon-only,
.green .p-checkbox .p-checkbox-box.p-highlight,
.green .p-selectbutton .p-button.p-highlight {
  background: var(--green-highlightbtn);
  border-color: var(--green-highlightbtn);
  color: #ffffff;
}

.green .p-selectbutton .p-button.p-highlight:hover,
.green .p-button:hover,
.green .p-button:enabled:hover,
.green .p-button:not(button):not(a):not(.p-disabled):hover,
.green .p-checkbox .p-checkbox-box.p-highlight:hover,
.green .p-selectbutton .p-button.p-highlight:hover {
  background: var(--green-highlightbtn-hover);
  border-color: var(--green-highlightbtn-hover);
  color: #ffffff;
}

/*individual component force color change*/
.green .react-datepicker__day--selected,
.green .react-datepicker__day--in-range,
.green .p-selectbutton.p-buttonset .p-button.p-highlight {
  background-color: var(--green-highlightbtn);
  color: #fff;
  border-color: transparent
}

.green .react-datepicker__day--selected:hover,
.green .react-datepicker__day--in-range:hover,
.green .p-selectbutton.p-buttonset .p-button.p-highlight:hover {
  background-color: var(--green-highlightbtn-hover);
  color: #fff;
}

.green .react-datepicker__day--keyboard-selected {
  background-color: #999;
}

.green .per-head-desc.bg-primary-100 {
  background-color: var(--green-100) !important;
}

/*------------------------------------------------------------------------------*/

/*Theme maroon*/
.maroon .p-selectbutton .p-button.p-highlight,
.maroon .p-button:not(.p-selectbutton .p-button):not(.editDelButton),
.maroon .p-button-icon-only,
.maroon .p-checkbox .p-checkbox-box.p-highlight,
.maroon .p-selectbutton .p-button.p-highlight {
  background: var(--maroon-highlightbtn);
  border-color: var(--maroon-highlightbtn);
  color: #ffffff;
}

.maroon .p-selectbutton .p-button.p-highlight:hover,
.maroon .p-button:hover,
.maroon .p-button:enabled:hover,
.maroon .p-button:not(button):not(a):not(.p-disabled):hover,
.maroon .p-checkbox .p-checkbox-box.p-highlight:hover,
.maroon .p-selectbutton .p-button.p-highlight:hover {
  background: var(--maroon-highlightbtn-hover);
  border-color: var(--maroon-highlightbtn-hover);
  color: #ffffff;
}

/*individual component force color change*/
.maroon .react-datepicker__day--selected,
.maroon .react-datepicker__day--in-range,
.maroon .p-selectbutton.p-buttonset .p-button.p-highlight {
  background-color: var(--maroon-highlightbtn);
  color: #fff;
  border-color: transparent
}

.maroon .react-datepicker__day--selected:hover,
.maroon .react-datepicker__day--in-range:hover,
.maroon .p-selectbutton.p-buttonset .p-button.p-highlight:hover {
  background-color: var(--maroon-highlightbtn-hover);
  color: #fff;
}

.maroon .react-datepicker__day--keyboard-selected {
  background-color: #999;
}
.maroon .react-datepicker__header{
  background-color: var(--maroon-highlightbtn) !important;
}

.maroon .per-head-desc.bg-primary-100 {
  background-color: rgba(155, 68, 68, 0.2) !important;
}
/*------------------------------------------------------------------------------*/

/*Dark mode color change*/
.dark .p-multiselect-panel .p-multiselect-items .p-multiselect-item span {
  color: #fff;
}

.dark ::ng-deep .p-dialog-footer{
  background-color: rgb(39, 49, 67) !important; color: white !important;
}

.dark .p-dialog-footer {
  background-color: black !important;
  color: white; /* Optional: ensures text is visible on a dark background */
}
.dark .example-custom-input{
  background-color: #1b2531 !important;
  color: #fff !important;
}

/*------------------------------------------------------------------------------*/

.light .text-primary{
  color: var(--blue-highlightbtn) !important;
}
.light .date-input .p-selectbutton .p-button.p-highlight,
.light .date-input .p-button:not(.p-selectbutton .p-button):not(.editDelButton),
.light .date-input .p-button-icon-only,
.light .date-input .p-checkbox .p-checkbox-box.p-highlight,
.light .date-input .p-selectbutton .p-button.p-highlight {
  background: #fff ;
  border: transparent ;
  color: var(--blue-highlightbtn) ;
}
.light .example-custom-input{
  background: #fff;
  border: 1px solid #ced4da;
  color: #000;
}


.date-input .example-custom-input{
  border: 1px solid #ced4da;
}


.light .inner_dropdown, 
.select_width_issue,
.p-buttonset .p-button:not(.p-highlight){
 border-color: var(--blue-highlightbtn-hover) !important;
}
.cs_selectBtn .p-buttonset .p-button:not(:last-child){
  border-right: 1px solid transparent !important;
}
.selectBtn .p-buttonset .p-button:not(.p-highlight){
  border-color: #ced4da !important;
 }
 .p-confirm-dialog .p-confirm-dialog-reject{
  background-color: #ebf5ff  !important;
  border-color: #ebf5ff !important;
  color: var(--blue-highlightbtn) !important;
}
.p-confirm-dialog .p-button.p-confirm-dialog-accept.p-button-danger{
  background-color: var(--blue-highlightbtn) !important;
  border-color: var(--blue-highlightbtn) !important;
}
 .del_modal .p-dialog{
  border-radius: 10px !important;
 }
 .del_moda .p-dialog .p-dialog-header{
  border-radius: 10px 10px 0 0 !important;
 }
.del_modal .p-button.p-confirm-dialog-accept,
.del_modal .p-button.p-confirm-dialog-accept{
  background-color: var(--blue-highlightbtn) !important;
  border-color: var(--blue-highlightbtn) !important;
}
.del_modal .p-confirm-dialog-reject{
  background-color: #ebf5ff  !important;
  border-color: #ebf5ff !important;
  color: var(--blue-highlightbtn) !important;
}
.del_modal .p-dialog-footer,
.p-confirm-dialog .p-dialog-footer
{
  justify-content: center !important ;
  align-items: center !important;
  border-top: none !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
  border-color: var(--blue-highlightbtn) !important;
}

.react-datepicker__input-container .example-custom-input{
  background: #fff;
  border: 1px solid transparent;
}
.date-comp-prev-btn{
  border-radius: 6px 0 0 6px !important;
}
.date-comp-next-btn{
  border-radius: 0 6px 6px 0  !important;
}

.react-datepicker__month-text--keyboard-selected{
  background: var(--blue-highlightbtn-hover) !important;
  color: #fff !important;
}
.react-datepicker__month-container{
  min-width: 220px;
}
.monthCont{
  min-width: 120px;
}

.editDelButton.p-button.p-button-icon-only {
  background: none !important;
  width: 27px !important;
  height: 27px !important;
  padding: 3px !important;
  border: 1px solid !important;
}
.p-button.p-button-icon-only.editDelButton.editBtn {
  color: var(--blue-highlightbtn) !important;
  border-color: var(--blue-highlightbtn) !important;
}
.p-button.p-button-icon-only.editDelButton.delBtn {
  color: red !important;
  border-color: red !important;
}
/* .p-inputtext{
  border: none !important;
} */
.badge{
  width: 18px;
  height: 18px;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
  background: var(--blue-highlightbtn-hover);
}
.badge-circle{
  border-radius: 50% !important;
}
.badge.badgeInactive {
  background: var(--bs-gray-500);
}



.fs-7{font-size:.95rem!important}
.fs-8{font-size:.85rem!important}
.fs-9{font-size:.75rem!important}
.fs-10{font-size:.5rem!important}

button:hover {
  opacity: 0.8;
}


/*Main css start*/
.main {
  flex: 5;
}

hr {
  margin: 2px 0 !important;
}
.p-card .p-card-body{
  padding: 1rem !important;
}
.project_billing .p-toolbar {
  align-items: start;
}
.p-toolbar .p-toolbar-group-start h4{
  margin-bottom: 0 !important;
  line-height: 1.6;
  font-size: 20px;
  font-weight: 600;
}
.p-icon {
  width: 1rem;
  height: 1rem;
  color: #666;
}
.report-end-tool{
  padding-right: 5px;
}
.light .TPE_lightbg, .TPE_lightbg{
  background-color: #F5FAFF;
}
.dark .TPE_lightbg{
  background-color: #1b2531 !important;
    color: white !important;
}
.primaryBg{
  background-color: var(--blue-highlightbtn) !important;
}
.lightBg{
  background-color: var(--extralightblue) !important;
}
.cs_card_shadow {
  box-shadow: 0px 2px 10px rgba(159, 209, 255, 0.5);
}
.border_primary{
  border: 1px solid var(--blue-highlightbtn-hover) !important;
}

.theme-button {
  width: 2.3rem;
  height: 2.3rem !important;
  border: none;
}

.verticalDivider{
  width: 0.7px;
  background: #ccc;
  height: 30px;
  margin: 0 10px;
}
.searchBx{
  display: block;
  width: 100%;
  border-radius: var(--btn_border_radius);
  position: relative;
}
.searchBxCont{
  width: 300px;
}
.searchIcon{
  position: absolute;
  right: 10px;
  top: 7px;
  background: #fff;
  z-index: 2;
}

.sidebar-menu-item .ps-menu-button,
.p-inputtext,
.custom_date_range,
.p-dropdown,
.select_width_issue,
.example-custom-input,
.react-datepicker__input-container input[type=text] {
  height: var(--input_height_40) !important;
}
.select_width_issue{
  display: flex;
  align-items: center;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token{
  height: 26px;
  font-size: 0.9rem;
  line-height: 0;
  border-radius: var(--btn_border_radius);
}
.custom-multiselect{
  min-height: 2.5rem;
  align-items: center;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 5px 16px !important;
}

/* .RoleDialog{
  width: 45rem;
} */
.p-dialog{
  border-radius:10px !important;
}
.p-dialog .p-dialog-header .p-dialog-title{
  font-size: 1.1rem !important;
}
.p-dialog .p-dialog-footer {
  padding: 1rem !important;
  border-top: none !important;
  padding-top: 0 !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.p-dialog .p-dialog-footer>div {
  padding: 0px !important;
}

.p-dialog .p-dialog-footer button {
  margin-top: 0px !important;
}

.p-dropdown-panel .p-dropdown-header {
  background-color: inherit !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #99999941 !important;
}

.p-multiselect-panel .p-multiselect-header {
  background-color: inherit !important;
  color: inherit !important;
}

.react-datepicker-wrapper{
  flex: 1 1 0 !important;
}

.listActiveBg{
  background-color: #9FD1FF;
}
.head-text {
  font-weight: 600;
}
.leftPrimaryMainBg{
  /* background-color: var(--blue-highlightbtn); */
  border-radius: 30px 0 0 0;
}
.light.blue .leftPrimaryMainBg{
  background-color: #fff !important;
}
.dark.blue .leftPrimaryMainBg{
  background-color: #071426 !important;
}
.under-main-container {
  /* margin: 2rem; */
  margin: 1rem;
  margin-right: 0;
  height: calc(100vh - 110px); 
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}
.main-dashboard{
  height: calc(100vh - 170px); 
  overflow-y: auto;
  overflow-x: hidden;
}
.p-toolbar-group-end.p-toolbar-group-right{
  justify-content: right;
}
/* .under-main-container > div:first-child {
  margin-right: 0px;
} */

.projectListSingle{
  background-color: var(--bs-white);
}

.employeeNameIntial {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empName{
  line-height: 1.3;
}
.skillAvatar{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--extralightblue);
}
.cs_badgeIcon{
  position: absolute;
  top: -10px;
  right: -18px;
  color: #B43235;
  font-Size: 16px;
  cursor: pointer;
}
.p-inputtext {
  color: black;
}

.p-inputtext:enabled:focus,
.p-button:focus {
  box-shadow: none !important;
  border-color: var(--lightblue-highlightbtn) !important;
}

.admin_card.p-card .p-card-content {
  padding: 0 !important;
}

.parent-sel-btn .p-button,
.parent-sel-btn .p-highlight {
  position: relative;
}

.parent-sel-btn .p-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.parent-sel-btn .p-highlight::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) !important;
}

.cron-container h1,
.admin-cred-head {
  font-size: 1.2rem;
}

/* width */
::-webkit-scrollbar,
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.422);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: rgba(214, 213, 216, 0.918);
  /* background: var(--blue-highlightbtn); */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  background: rgb(124, 124, 124);
}

thead.p-datatable-thead {
  position: sticky;
  width: 100%;
  top: -2px;
  left: 0;
  z-index: 9;
}
/* .apexcharts-canvas,
.apexcharts-canvas>svg {
  width: 100% !important;
} */

#pr_id_2_content .p-button-label.p-c {
  flex: 1 1 auto;
}

/* .react-datepicker__month {
  text-align: left !important;
} */
.react-datepicker__year-wrapper {
  justify-content: center;
}

.sidebar_after {
  position: relative;
}
/* .endToolbar {
  flex-wrap: wrap !important;
} */
/* .sidebar-open .performance-table .p-datatable-wrapper {
  width: calc(100vw - 311px);
}

.sidebar-close .performance-table .p-datatable-wrapper {
  width: calc(100vw - 145px);
} */
.skill-icon.skill-avatar{
  background-color: var(--extralight_card_bg) !important;
  box-shadow: 0 0 5px #ccc;
}

.p-checkbox .p-checkbox-box.p-highlight{
  background: var(--blue-highlightbtn) !important;
  border-color: var(--blue-highlightbtn) !important;
}

.report-charts .p-card-title{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.p-toolbar-group-center:empty{
  display: none;
}
reportToolbar .p-buttonset .p-button{
  min-height: 35px;
}
@media screen and (max-width:1350px){
  .reportToolbar .p-buttonset .p-button{
    padding: 0.3rem 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .empSkillDialog{
    width: 30vw;
  }
  .AuditTable.audit-log-table .p-datatable-wrapper {
    max-width: calc(100vw - 21rem) !important;
  }
  .performanceReportTable .p-datatable-wrapper {
    max-width: calc(100vw - 21rem) !important;
  }
}
@media screen and (max-width: 1199px) {
  .empSkillDialog{
    width: 50%;
  }
  .AuditTable.audit-log-table .p-datatable-wrapper {
      max-width: calc(100vw - 45px) !important;
  }
  .roleTable.audit-log-table>.p-datatable-wrapper,
  .userMapTable.audit-log-table>.p-datatable-wrapper,
  .subKpiTable.audit-log-table>.p-datatable-wrapper,
  .kpiMapTable.audit-log-table>.p-datatable-wrapper,
  .userTable>.p-datatable-wrapper,
  .empolyee_table>.p-datatable-wrapper,
  .createAnsTable>.p-datatable-wrapper,
  .goalTable>.p-datatable-wrapper,
  .skillTable>.p-datatable-wrapper{
    max-width: calc(100vw - 45px) !important;
  }
  .performanceReportTable .p-datatable-wrapper {
    max-width: calc(100vw - 70px) !important;
  }
}

@media screen and (min-width:975px) {
  .reportToolbar .p-buttonset .p-button:nth-of-type(1):not(.p-highlight),
  .reportToolbar .p-buttonset .p-button:nth-of-type(2):not(.p-highlight),
  .reportToolbar .p-buttonset .p-button:nth-of-type(3):not(.p-highlight),
  .reportToolbar .p-buttonset .p-button:nth-of-type(4):not(.p-highlight),
  .reportToolbar .p-buttonset .p-button:nth-of-type(5):not(.p-highlight),
  .reportToolbar .p-buttonset .p-button:nth-of-type(6):not(.p-highlight)
  {
      border-right: none !important;
  }
}

@media screen and (max-width:974px) {
  .reportToolbar .p-buttonset .p-button{
      margin-right: 10px !important;
      margin-bottom: 10px !important;
      border-radius: var(--btn_border_radius) !important;
  }
  .reportToolbar .p-buttonset .p-button:not(.p-highlight){
      border-color: #ced4da !important;
  }
  
}


@media screen and (max-width: 600px) {
  .empSkillDialog{
    width: 75%;
  }
  .AuditTable.audit-log-table>.p-datatable-wrapper {
    max-height: calc(100vh - 500px );
    overflow: auto;
  }
  .performanceReportTable .p-datatable-wrapper {
    max-width: calc(100vw - 45px) !important;
  }
  
}

.AuditTable th.Parameters, .AuditTable th.BrowserInfo {
  min-width: 300px !important;
  width: 300px !important;
}
.AuditTable td {
  white-space: normal;
  word-break: break-word;
}

.AuditTable th.ReturnValue {
  min-width: 400px;
}
.AuditTable td.ReturnValue {
  white-space: normal;
  word-break: break-word;
}

/*---------Application setting page----------*/
.AppTabMenu .p-tabmenu-nav{
  gap: 10px;
  border: none !important;
}
.AppTabMenu .p-menuitem-link{
  padding: 10px 15px !important;
  font-weight: 500;
}
.AppTabMenu .p-tabmenuitem .p-menuitem-link{
  border: none !important;
  border-radius: var(--btn_border_radius) !important;
}
.AppTabMenu .p-tabmenuitem.p-highlight .p-menuitem-link{
  border: none !important;
  border-radius: var(--btn_border_radius) !important;
}
.light .AppTabMenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
  background-color: var(--extralightblue);
  height: 100% !important;
}
.light .AppTabMenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  background-color: var(--blue-highlightbtn) !important;
  color: var(--extralightblue) !important;
}
/*---------End Application setting page----------*/

/*================Prime ng style modify================*/
.p-toolbar {
  padding: .6rem;
}
.p-toolbar .p-multiselect{
  border-color: var(--blue-highlightbtn) !important ;
}
.p-card{
  box-shadow: 0px 0px 8px rgba(159, 209, 255, 0.5) !important;
  border-radius: var(--card_border_radius) !important;
}
.p-card .p-card-content {
  padding: 0 !important;
}
.p-chip {
  background-color: #e6effc !important;
  color: var(--blue-highlightbtn) !important ;
  border-radius: 0px !important;
  padding: 0px 0.75rem !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon{
  border-radius: var(--btn_border_radius) !important;
  color: var(--blue-highlightbtn) !important;
  background: var(--bs-white) !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  border-color: transparent;
  background: var(--blue-highlightbtn) !important;
  opacity: 1 !important;
}
/* .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover .p-icon{
  color: #e6effc !important;
} */
.p-dialog-header{
  background-color: var(--blue-highlightbtn-hover) !important;
  /* border-bottom: 1px solid #ccc !important; */
}
.p-dialog-header h1, .p-dialog .p-dialog-header .p-dialog-title, .p-dialog-header-close:hover .p-icon{
  color: var(--bs-white) !important;
}
.p-dialog-header-close .p-icon{
  fill: #fff !important;
}

.p-overlaypanel-content .p-button{
  background: var(--blue-highlightbtn);
  border: 1px solid var(--blue-highlightbtn);
}
.p-overlaypanel-content .p-button:hover{
  background: var(--blue-highlightbtn-hover) !important;
  border: 1px solid var(--blue-highlightbtn-hover) !important;
}
/* .p-button.p-button-icon-only {
  width: 35px !important;
  padding: 0.5rem 0 !important;
  height: var(--input_height_40);
  justify-content: center;
  display: flex;
} */
.p-button.p-button-icon-only {
  width: 35px !important;
  padding: 0.5rem 0 !important;
  height: var(--input_height_40);
  justify-content: center;
  display: flex;
  /* background: transparent !important;
  border: none !important; */
  /* color: var(--bs-danger) !important; */
}
.ps-menu-button{
  background-color: transparent !important;
}
.ps-menu-button:hover{
  background-color: white !important;
}
.ps-menu-button.ps-open{
  border: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.2) !important;
  border-radius: 0 !important;
}
.ps-menu-button.ps-open:hover{
  color: white !important;
}
.ps-menu-button,
.upper-navbar-user-image {
  height: var(--input_height_40) !important;
  display: flex;
  align-items: center;
}
.ps-menu-button{
  border-radius: 0 !important;
}

.p-button {
  padding: 0.5rem 1.0rem;
  height: var(--input_height_40);
}
.p-buttonset .p-button:not(:last-child) {
  border-right: 1px solid #ced4da;
}
.p-dialog .field {
  gap: 0 !important;
}
.p-dialog-header h4, .user-goal-description{
  color: var(--bs-white);
  margin-bottom: 0;
}
.p-dialog .field>label {
  margin-bottom: 0.25rem;
}


.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #EFF6FF;
  color: var(--blue-highlightbtn) !important;
}
.p-treenode-icon{
  color: var(--blue-highlightbtn) !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content{
  padding: 0.25rem !important;
}

.p-dialog .p-dialog-footer button {
  margin: 0;
  width: auto;
  border-radius: 6px !important;
  padding: 0.3rem 2rem !important;
}
.modalsecondaryBtn{
  background-color: #ebf5ff !important;
  border: none !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: inherit !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--text-color) !important;
}
.projectAdd.addBtn button{
  display: flex;
  gap:0.5rem;
  justify-content: center;
}
.projectAdd.addBtn button span.p-button-icon{
  order: 2;
  margin-right: 0 !important;
}
.projectAdd.addBtn button span.p-button-label{
  order: 1;
}


.p-confirm-dialog .p-dialog-header,
.p-dialog-header {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  border-top-left-radius:10px !important;
  border-top-right-radius:10px !important;
}

.p-confirm-dialog .p-dialog-content,
.p-dialog-content {
  padding: 1.5rem !important;
}

.p-confirm-dialog .p-dialog-footer,
.p-dialog-footer {
  padding-top: 20px;
  display: flex;
  gap: .5rem;
  justify-content: end;
}

.p-confirm-dialog-message {
  margin-left: 0 !important;
  text-align: center;
}

.p-multiselect .p-multiselect-label {
  padding: 1.4px 0.75rem;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label:not(.endToolbarPerformance .p-dropdown-label) {
  padding-top: 6px;
  padding-bottom: 6px;
}
.upper-navbar-language .p-dropdown{
  border: none;
  
}
.p-dropdown:not(.p-disabled).p-focus{
  box-shadow: none !important;
}
.upper-navbar-language .p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 14px;
  padding: 8px 5px;
}

.kpi-save-button {
  margin-top: 15px !important;
}
.p-datatable .p-datatable-tbody > tr.p-row-odd td{
  background-color: var(--extralightblueOne) !important;
}
.p-datatable .p-datatable-tbody>tr>td,
.p-datatable .p-datatable-tbody>tr>th,
.p-datatable .p-datatable-thead>tr>th {
  padding: 1rem 1rem !important;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th{
  border: 0 !important;
}

.empolyee_table, .performanceTableCont, .p-datatable, .cs_card, .goal_card {
  border-radius: var(--card_border_radius);
  overflow: hidden;
}
.p-slider .p-slider-range, .p-slider:not(.p-disabled) .p-slider-handle:hover {
  background:var(--blue-highlightbtn) !important;
}
.p-slider .p-slider-handle{
  border: 2px solid var(--blue-highlightbtn) !important;
}
.p-tabview .p-tabview-panels{
  padding-bottom: 0 !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link{
  padding: 0.8rem !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
  color: var(--blue-highlightbtn) !important;
  border-color: var(--blue-highlightbtn) !important;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar{
  background:transparent !important;
}


.assignToEmployeeTable th{
  background-color: var(--extralightblue) !important;
}

.kpi_tabile_res .p-datatable .p-datatable-tbody>tr>td{
  padding:0.35rem 1rem  !important;
  vertical-align: middle;
}
.col_center .p-column-header-content {
  justify-content: center;
}

.col_150 {
  width: 150px;
}
.p-button:focus{
  box-shadow: none !important;
}
.p-tag.p-tag-rounded {
  width: 16px;
  height: 16px;
}
.p-tag.p-tag-rounded.activeIcon{
  background-color: rgb(37, 206, 37);
}
.infoIcon{
  width: 30px;
  height: 30px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue-highlightbtn);
  border-radius: 6PX;
}
.infoIcon i{
  color: #12982A !important;
  font-size: 1.2rem !important;
}

.rdrDayToday .rdrStartEdge.rdrEndEdge{
  background: var(--blue-highlightbtn);
}

.p-tag.p-tag-rounded.inactiveicon{
  background-color: #B00909;
}
span.p-tag-value {
  display: flex;
  align-items: center;
}
.per-view-btn {
  border-color: #ccc !important;
}
.p-multiselect .p-multiselect-label {
  padding: 0.35rem 0.75rem !important;
}

span.p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
}

.inner_dropdown span.p-dropdown-label.p-inputtext {
  display: block !important;
  padding-top: 6px !important;
}


.dark .react-datepicker__day {
  color: white !important;
}

.dark .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: white !important;
}

.dark .react-datepicker__day:hover {
  background: #797979 !important;
}


/*table pagination*/
.p-paginator {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  border: none !important;
}
@media only screen and (max-width: 575px) {
  .p-paginator {
    justify-content: center !important;
  }
  .p-paginator .p-paginator-current{
      width: 100%;
  }
}
.p-paginator-page.p-paginator-element.p-link,
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  width: 25px;
  height: 25px !important;
  min-width: 25px !important;
  border-radius: var(--btn_border_radius) !important;
}
.blue .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  background: var(--blue-highlightbtn) !important;
  color: #fff !important;
}
.blue .p-paginator .p-dropdown{
  border: 1px solid var(--blue-highlightbtn);
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link{
  border: transparent;
}

.custom-accordion.p-accordion {
  border: 1px solid var(--blue-highlightbtn);
}

/*======Responsive =========*/
@media screen and (max-width:1200px) {
  .sidebar_after::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    z-index: 99;
  } 
  .leftPrimaryMainBg{
    border-radius: 0 !important;
  }
}

@media only screen and (max-width: 991px) {
  .under-main-container {
    margin: 1rem;
    /* height: 94vh; */
    padding-right: 0;
  }
  
  .react-datepicker__month-container {
    float: left;
    width: 275px;
  }
}

@media only screen and (max-width: 767px) {
  
  .under-main-container::-webkit-scrollbar,
  .p-accordion .p-accordion-content::-webkit-scrollbar {
    width: 0px;
  }

  .pr-1 {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-right {
    justify-content: flex-start !important;
    align-items: flex-start;
    text-align: left;
  }

  .dark .p-dialog .p-dialog-footer{
    background-color: #1b2531;
  }

}

@media only screen and (max-width: 600px) {
  .emp-performance-chart {
    display: flex;
    gap: 15px;
    flex-direction: column;
  }

  .emp-per-chart-box {
    max-width: 100% !important;
    min-height: auto !important;
  }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    padding: 5px;
  }
}


@media only screen and (max-width: 575px) {
  .nav-item-name {
    font-size: 13px;
  }
  .p-button{
    overflow: hidden;
  }
  .main-your-performance-card{
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 480px) {
  
  .nav-single-item:hover .nav-item-name-onclick {
    display: block !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 12px;
    padding: 0.35rem 1rem;
  }

  .p-dropdown-panel .p-dropdown-items {
    padding: 0.15rem 0;
  }

  /* .upper-navbar-avatar {
    width: 2rem;
    height: 2rem;
  } */

  .logo {
    width: 100%;
    margin: 0rem 0 2rem 0rem;
  }

  .p-dropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: white;
    font-size: 13px;
    padding: 7px;
  }

  .nav-item-icon {
    font-size: 1rem;
  }

  .nav-single-item-onclick {
    width: 8rem;
  }

  .navbar-onclick {
    width: 170px;
  }

  .nav-single-item {
    gap: 0.3rem;
  }

  .p-card .p-card-body{
    padding: 0.45rem !important;
  }
  /* .p-datatable .p-datatable-tbody>tr>td, .p-datatable .p-datatable-tbody>tr>th, .p-datatable .p-datatable-thead>tr>th{
    padding: 0.3rem !important;
  } */
  .p-dialog{
    max-width: 96%;
  }
  .endToolbar{
    flex-wrap: wrap;
  }

}

.del_modal .p-dialog-footer{
  justify-content: center !important ;
  align-items: center !important;
  border-top: none !important;
}

.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 2px;
}

.apexcharts-canvas svg {
  margin-top: 0px;
  /* border: 1px solid #ededed; */
  border-radius: var(--card_border_radius);
}
.emp-performance-chart .apexcharts-canvas svg{
  /* padding-top: 15px; */
}
/* .apexcharts-toolbar {
  top: -25px !important;
} */

.apexcharts-toolbar {
  right: -6px !important;
  top: -58px !important;
}
.report-charts .apexcharts-toolbar {
  right: 0px !important;
  top: -45px !important;
}


.apexcharts-menu-icon svg {
  border: none;
  fill: var(--blue-highlightbtn) !important;;
}

.dark .card {
  background-color: #1b2531 !important;
  color: white !important;
}

.cx_checkbox {
  width: 16px;
  height: 16px;
  border-radius: var(--btn_border_radius);
  position: relative;
}
.cx_checkbox input{
  opacity: 0;
}
.cx_checkbox input:checked{
  /* background: #0355A0; */
  /* box-shadow: 0 0 0 3px var(--blue-highlightbtn-hover) inset; */
  border: 5px solid var(--blue-highlightbtn-hover);
  border-radius: var(--btn_border_radius);
}
.cx_checkbox:before{
  position: absolute;
  width: 17px;
  height: 17px;
  content: "";
  border-radius: var(--btn_border_radius);
  top: 0;
  right: 1px;
  border: 1px solid var(--blue-highlightbtn-hover);
  pointer-events: none;
}
.listActiveBg  .cx_checkbox:before{
  border: 1px solid #fff;
  background-color: var(--blue-highlightbtn-hover);
}
.listActiveBg  .cx_checkbox:after {
  position: absolute;
  width: 7px;
  height: 7px;
  content: "";
  border-radius: 2px;
  top: 5px;
  right: 6px;
  background-color: #fff;
  pointer-events: none;
}
.employeeSkillSingle img.p-avatar-img{
  width: 3rem !important;
  height: 3rem !important;
}


.violet .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--violet-highlightbtn-hover);
  border-color: var(--violet-highlightbtn-hover);
}

.green .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--green-highlightbtn-hover);
  border-color: var(--green-highlightbtn-hover);
}
.maroon .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--maroon-highlightbtn-hover);
  border-color: var(--maroon-highlightbtn-hover);
}

.violet .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  color: var(--violet-highlightbtn-hover) !important;
}

.green .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  color: var(--green-highlightbtn-hover) !important;
}
.maroon .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  color: var(--maroon-highlightbtn-hover) !important;
}

.dark .p-paginator .p-paginator-pages .p-paginator-page {
  color: white !important;
}

.del_modal .p-dialog-footer button,
.p-confirm-dialog .p-dialog-footer button
{
  border-radius: 6px !important;
  padding: 0.3rem 2rem !important;
  margin-top: -22px !important;
  margin-bottom: 15px;
  margin-top: -17px !important;
  margin-bottom: 14px !important;
}
.p-confirm-dialog .p-dialog-footer,
.del_modal .p-dialog-footer{
  padding-top: 1rem !important;
  border-top: none !important;
}
.admin_password input[type="password"]{
  width: 100%;
}
.Tableborder_body .p-datatable-wrapper{
  border: 1px solid var(--primary-color) !important;
}