.assign-goal-form {
  width: 100%;
}

.header {
  height: 40px;
}

.custom-dropdown {
  border: none !important;
  border-bottom: 1px solid rgb(160, 156, 156) !important;
  border-radius: 0;
}

.form-group label {
  flex-basis: 20%;
  font-weight: 400;
}

.form-group .custom-dropdown {
  flex-basis: 75%;
}

.goal-desc-heading {
  font-weight: bold;
  margin: 10px 0;
}

.assign-goal-form hr {
  width: 100%;
  color: rgb(129, 129, 124);
  margin: 10px 0;
}

.goal-desc-input {
  border: none;
  border-bottom: 1px solid rgb(160, 156, 156);
  padding: 5px;
  width: 100%;
}

.p-checkbox-box .p-checkbox-icon {
  color: white;
  /* This changes the checkmark color */
}

.p-stepper-number {
  border-radius: var(--btn_border_radius) !important;
  /* Makes the step number container square */
  width: 2em;
  /* Adjust the width as necessary */
  height: 2em;
  /* Adjust the height as necessary */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.goal-form .form-group label {
  font-weight: 400;
  color: black;
  margin-bottom: 8px;
}

.p-checkbox-box .p-checkbox-icon {
  color: white !important;
}

.p-stepper-number {
  height: 35px;
  width: 35px;
  border-radius: var(--btn_border_radius);
}

.goal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.goal-step {
  display: grid;
  grid-template-columns: 35px 1fr;
  position: relative;
  min-height: 60px;
}
.goal-step button{
  border-radius: 50%;
}
.goal-step button:hover{
  opacity: 1 !important;
}

.goal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.goal-connector {
  width: 2px;
  height: 97%;
  position: absolute;
  left: 16px;
  top: 37px;
  border-right: 1px dashed;
}

.goal-step:last-child .goal-connector {
  display: none;
}

.goal-input {
  height: auto;
  max-height: 2rem;
}

.assign-goal-form .goal-wrapper .goal-step {
  display: grid !important;
  grid-template-columns: 35px 1fr 75px;
}

.assign-goal-form .goal-wrapper .goal-step button {
  padding: 0.5rem !important;
  justify-content: center;
}

.step-description-and-score-container .p-inputnumber {
  width: 50px !important;
}

.custom-dropdown .p-dropdown-panel {
  width: 541px !important;
}

.custom-dropdown .p-dropdown-header .p-dropdown-panel {
  width: 541px !important;
}

.custom-dropdown .p-dropdown-items-wrapper {
  width: 541px !important;
}

.custom-dropdown .p-dropdown {
  width: 541px !important;
}

.err-msg {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}

.dropdown-styles {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
}
