p {
  margin-bottom: 0 !important;
}
.main-project-billing, .main_project_billing {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.main-project-billing::-webkit-scrollbar {
  background: transparent;
  /* Chrome/Safari/Webkit */
  width: 0px;
}
.employeeBillingCont .p-toggleable-content .p-accordion-content,
.main_project_billing .p-toggleable-content .p-accordion-content{
  padding: 0 !important;
}
.employee-billing-employee-part {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  font-size: 14.3px;
}
.employeeBillingCont .employee-billing-head.p-accordion-header:not(.seceond_head) a,
.main_project_billing .employee-billing-head.p-accordion-header:not(.seceond_head) a{
  background-color: var(--blue-highlightbtn) !important;
  color: var(--bs-white) !important;
} 

.employee-billing-project-part,
.employee-billing-task-part {
  padding: 0.5rem 1.5rem;
  padding-right: 0;
  cursor: pointer;
}
.p-accordion .p-accordion-tab{
  margin: 0;
}
.employee-billing-project-part {
  display: flex;
  gap: 2.5rem;
  align-items: center;
  padding: 0 25px;
  font-size: 14.3px;
}

.employee-billing-task-part {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.employee-billing-task-part > span,
.employee-billing-project-part > span,
.employee-billing-employee-part > span {
    flex: 1 1;
    line-height: 18px;
    font-size: 0.8rem;
}
span.employee-billing-emp-name{
  word-break: break-word;
}
.input-for-billing-time-main {
  display: flex;
  gap: 0.1rem;
  align-items: center;
  cursor: pointer;
}
.totalTrackTime, .billingTime{
  padding-left: 0;
}
.input-for-billing-time {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.input-for-billing-time > label {
  cursor: pointer;
}

/* .input-for-billing-time-size{
    width: 3rem;
} */

.empoloyee_sec .p-card .p-card-content {
  /* display: flex; */
  gap: 1rem;
  justify-content: space-between;
}

.no-data {
  color: #928989;
  font-size: 1rem;
  text-align: center;
  text-transform: capitalize;
}

.emp-bill-empCode {
  visibility: hidden;
}

.employee-billing-emp-name:hover .emp-bill-empCode {
  visibility: visible;
}

.inner_dropdown.p-dropdown .p-dropdown-label.p-placeholder,
.inner_dropdown.p-dropdown .p-dropdown-trigger {
  color: #6c757d;
}

.p-toolbar.p-component {
  /* position: sticky; */
  position: relative;
  top: -2px;
  z-index: 99;
}
.p-multiselect-panel {
  z-index: 9;
}
.employee-billing-employee-part > span::-webkit-scrollbar {
  width: 2px;
}
.input_billing .p-inputtext {
  color: #fff;
  background: transparent;
  font-size: 13px;
  border: transparent;
  font-weight: bold;
}
.input_billing .p-inputtext:focus,
.input_billing .p-inputtext:hover {
  border: transparent;
}

/* Track */
.employee-billing-employee-part > span::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.employee-billing-employee-part > span::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.employee-billing-employee-part > span::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.employee-billing-dates-part {
  display: flex;
  gap: 20px;
}
.p-toggleable-content .p-accordion-header-link:focus,
.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  box-shadow: none !important;
}
.p-toggleable-content .p-accordion.p-component {
  border: none;
  padding: 0 !important;
}
.p-toggleable-content .p-accordion .p-accordion-content {
  border: none !important;
}
.p-toggleable-content .p-accordion-header .p-accordion-header-link {
  padding: 0;
  border: none !important;
  outline: none;
}
.p-toggleable-content .p-accordion-header .p-accordion-header-link svg{
  position: absolute;
}
/* .custom-accordion{
  border: 1px solid #e0cece;
  height: fit-content;
} 
.custom-accordion .p-accordion-header{
  padding: 0.4rem;
}*/
.main-project-billing .p-toggleable-content .p-accordion-content{
  border: none;
}
.p-toggleable-content .p-accordion-header{
  background:#9FD1FF ;
}
.p-toggleable-content .p-accordion-header span,
.p-toggleable-content .p-accordion-header svg{
  color: #000;
}
.p-toggleable-content .p-accordion-header .employee-billing-task-part{
  border: none;
}
.employee-billing-head.employee-top-head .task-name{
  padding-left: 2.2rem;
}
.employee-billing-head.employee-top-head th,
.employee-billing-emp-name, .totalTrackTime, .billingTime,
.employee-billing-task-part > span{
  font-size: 0.9rem;
  line-height: 1.4;
  font-weight: 600;
  padding: 0;
}
.employee-billing-head.employee-top-head .p-accordion-toggle-icon{
  position: absolute;
}
.employee-billing-head .p-accordion-toggle-icon:not(.employee-billing-head.seceond_head .p-accordion-toggle-icon){
  color: #fff !important;
}


.p-accordion
.p-accordion-header
.p-accordion-header-link
.p-accordion-toggle-icon {
  margin-right: 0rem;
}
.input_billing {
  position: relative;
}

.task-name:hover > .task-name-tooltip{
  display: block;
}

.wrap_all{
  white-space: normal;
  word-break: break-all;
}
th.emp_code.emp_code_last {
  /* width: 100px; */
  text-align: center;
  margin: auto;
}
.p-toggleable-content-enter-done .p-accordion .employee-billing-head.seceond_head .p-accordion-content{
  margin-bottom: 4px;
}
.p-toggleable-content-enter-done .p-accordion:nth-last-of-type(1) .employee-billing-head.seceond_head .p-accordion-content{
  margin-bottom: 0px;
}
.employee-billing-head.seceond_head{
  padding: 5px 1.25rem;
}
.main_project_billing .employee-billing-head.seceond_head{
  background-color: #9FD1FF;
  border-bottom: 1px solid #fff;
}
.main_project_billing .employee-billing-head.seceond_head:nth-last-of-type(1){
  border-bottom: 1px solid transparent;
}
.ebtp.employee-billing-task-part{
  background-color: #DCEEFF;
}
.p-accordion .p-accordion-tab:nth-last-of-type(1){
  margin-bottom: 0 !important;
}
.performanceCont.p-accordion .p-accordion-content{
  /* border: 1px solid #dee2e6  !important; */
}

.employee-billing-head .p-accordion-header-link svg{
  position: absolute;
}

.employee-billing-head.seceond_head .p-accordion-header-link{
  background: none !important;
  /* padding: 5px 1.25rem !important; */
  padding: 5px 0rem !important;
}
.employee-billing-head.seceond_head .p-accordion-header-link svg{
  position: absolute;
  top: 16px;
}
.empAcord.p-accordion .p-accordion-header .p-accordion-header-link,
.empAcord.p-accordion .p-accordion-header .p-accordion-header-link:hover{
  border: 1px solid transparent !important;
}
.ebtpDate {
  position: relative;
  left: 17px;
}
.ebtpTime{
  position: relative;
  left: 7px;
}
.ebtpHour{
  position: relative;
  left: -15px;
}


/* Responsive design 1280 and 1920 ***************/
@media screen and (min-width: 1900px) {
 .main-project-billing .p-accordion.p-component {
    width: auto;
  }
}
@media screen and (max-width: 1900px) {
  .employee-billing-head,
  .employee-billing-task-part {
    width: 100%;
  }

  .employee-billing-employee-part {
    gap: 1.3rem;
  }
  .main-project-billing, .main_project_billing{
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1399px) {
  .main-project-billing, .main_project_billing{
    grid-template-columns: 1fr 1fr;
  }
  .employee-billing-task-part{
    gap: 3rem;
  }
}

@media only screen and (max-width: 1199px) {
  .main-project-billing .p-accordion .p-accordion-header .p-accordion-header-link{
    min-height: inherit;
  }
  
  .main-project-billing .p-accordion.p-component {
    width: 100%;
  }
  .employee-billing-employee-part {
    gap: 5rem !important;
    font-size: 16px;
  }
  .employee-billing-task-part {
    gap: 6rem;
  }
  .employee-billing-employee-part > span {
    display: inline-block;
    width: 185px;
  }
  .empolyee_table p-datatable .p-sortable-column .p-column-title {
    width: 100%;
    display: inline-block;
  }
  .main-project-billing, .main_project_billing{
    grid-template-columns: 1fr;
  }
 
}

@media only screen and (max-width: 991px) {
  .p-toggleable-content,
  .p-accordion-header {
    width: 100% !important;
  }

  .endToolbarPerformance {
    flex-wrap: wrap;
  }

  .empoloyee_sec .p-accordion-header,
  .empoloyee_sec .p-accordion-header-link,
  .empoloyee_sec .p-toggleable-content {
    width: 100% !important;
  }
  /* .p-datatable-table {
    border-spacing: 0;
    width: 120%;
    overflow: auto;
  } */
  .employee-billing-employee-part {
    gap: 2rem !important;
    font-size: 15px;
  }
  .empolyee_table .p-datatable-table .p-sortable-column .p-column-title
  {
    font-size: 14px;
  }
  .employee-billing-task-part > span, .employee-billing-project-part > span, .employee-billing-employee-part > span{
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {

  .employee-billing-employee-part,
  .employee-billing-project-part,
  .employee-billing-task-part {
    gap: 3rem;
  }

  .empoloyee_sec .p-card .p-card-content {
    flex-direction: column;
  }

  .employee-billing-employee-part > span {
    width: 100%;
  }
  .empolyee_table .p-datatable-wrapper{
    height: calc(100vh - 350px );
  }
  .empolyee_table
    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    max-width: 80px;
  }
  /* .empolyee_table .p-datatable-table {
    width: 195%;
  } */
  .emp_code {
    width: 150px;
  }
  .Name_code {
    max-width: 600px;
  }
  .select_width_issue .p-multiselect-label {
    min-width: 400px !important;
    max-width: 450px !important;
  }
  .input_billing .p-inputtext {
    max-width: 130px;
  }
  .employee-billing-employee-part {
    gap: 1rem !important;
  }
}

@media only screen and (max-width: 650px) {
  /* .empolyee_table .p-datatable-table {
    width: 195%;
  } */
  .employee-billing-task-part {
    padding: 0.8rem 0.9rem;
    padding-left: 1.25rem;
  }
  
}
@media only screen and (max-width: 575px) {
  .select_width_issue .p-multiselect-label {
    min-width: 300px !important;
    max-width: 350px !important;
  }
  .empolyee_table .p-datatable-wrapper{
    height: calc(100vh - 400px );
  }

  .employee-billing-employee-part,
  .employee-billing-project-part,
  .employee-billing-task-part {
    flex-direction: row;
    align-items: center;
    gap: 1.7rem;
    font-size: 15px;
  }

  .empoloyee_sec .p-accordion-header,
  .empoloyee_sec .p-accordion-header-link,
  .empoloyee_sec .p-toggleable-content {
    width: 100% !important;
  }
  .employee-billing-employee-part,
  .employee-billing-project-part,
  .employee-billing-task-part {
    font-size: 11px;
    line-height: 15px;
  }
  .seceond_head .p-accordion-header-link{
    padding: 0 !important;
  }
  /* .emp_code_last {
    max-width: 15px;
  } */
  .employee-billing-task-part > span,
  .employee-billing-project-part > span,
  .employee-billing-employee-part > span {
    line-height: 15px;
  }
  /* .empolyee_table .p-datatable-table {
    width: 205%;
  } */
  .p-multiselect-panel.p-component {
    max-width: inherit !important;
    width: 250px;
    left: 25px !important;
  }
  .input_billing .p-inputtext {
    max-width: 100px;
  }
}


@media only screen and (max-width: 480px) {
  .select_width_issue .p-multiselect-label {
    min-width: 200px !important;
    max-width: 240px !important;
  }
  .empolyee_table
    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    min-width: 50px;
    font-size: 12px;
    /* word-break: break-all !important; 
    word-wrap: break-word;*/
    white-space: normal;
  }
  /* .Name_code{
    width: 150px !important;
  } */
  /* .emp_code {
    width: 105px;
    font-size: 12px;
  } */
  .employee-billing-task-part {
    gap: 2.5rem;
  }
  .employee-billing-employee-part {
    gap: 1rem !important;
  }
  .seceond_head .p-accordion-header-link{
    padding: 0 !important;
  }
  /* .empolyee_table .p-datatable-table {
    width: 210%;
  } */
  /* .endToolbar input[type="search"] {
    width: 70% !important;
  } */
  .input_billing .p-inputtext {
    max-width: 60px;
    font-size: 10px;
  }
  .employee-billing-task-part > span,
  .employee-billing-project-part > span,
  .employee-billing-employee-part > span {
    font-size: 10px !important;
  }
  .empolyee_table .p-datatable-table .p-sortable-column .p-column-title {
    font-size: 12px;
}
.p-tag.p-tag-rounded {
  width: 20px;
  height: 20px;
}
}
@media only screen and (max-width: 360px) {
  .select_width_issue .p-multiselect-label {
    min-width: 170px !important;
    max-width: 200px !important;
  }
  /* .Name_code {
    max-width: 30px;
  } */
  /* .empolyee_table .p-datatable-table {
    width: 231%;
  } */
  /* .emp_code_last {
    max-width: 75px;
  } */
  .empolyee_table
    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    max-width: 20px;
  }
}
