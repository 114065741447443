.email-settings-header {
  padding-bottom: 0.4rem;
}

.all-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.input-groups {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 49.2%;
}

.email-settings-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-input-groups {
  display: flex;
  gap: 1rem;
}

.check-groups {
  display: flex;
  gap: 9.4px;
}

.dark .mail-input {
  background-color: #1b2531;
  color: white;
}

.email-settings-btn {
  display: flex;
  gap: 0.5rem;
}

@media screen and (max-width: 1280px) {
  .input-groups {
    width: 49%;
  }
}

@media screen and (max-width: 991px) {
  .input-groups {
    width: 100%;
  }

  .email-settings-footer .input-groups {
    width: 75%;
  }

  .credential_btn {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .email-settings-footer .input-groups {
    width: 68%;
  }
}

@media screen and (max-width: 575px) {
  .email-settings-footer {
    flex-direction: column;
    gap: 1rem;
  }

  .email-settings-footer .input-groups {
    width: 100%;
  }

  .credential_btn {
    width: 220px;
  }
}