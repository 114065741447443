.skill-chip{
    display:flex;
    font-size:14px;
    flex-wrap:wrap;
    width: max-content;
    margin:5px;
}

.skill-icon{
    cursor:pointer;
    font-size:16px;
    width: 25px;
    height: 25px;
}

.skill-avatar{
    background-color:transparent;
    padding:5.2px;
}
.goal_table_main .p-datatable .p-datatable-tbody>tr>td{
    padding: 0.9rem 1rem !important;
}
.goal_table_main td.skill_value .skill-avatar{
    padding: 8.2px;
}
.goal_table_main td.skill_value .skill-icon{
    width: 32px;
    height: 32px;
}
.goal_table_main td.skill_value span.text-danger{
    position: absolute;
    background-color: #13BA32 !important;
    min-width: 20px;
    height: 20px;
    border-radius: 25px;
    color: #fff !important;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    padding: 3px;
    transform: translate(18px, -13px);
}