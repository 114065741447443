.header {
  margin-bottom: 20px;
  padding: 10px;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
}
.goal-form {
  background-color: #fff;
  border-radius: var(--card_border_radius);
  padding: 20px;

  width: 100%;
  position: relative;
}

/* .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
} */

.goal-form .form-group label {
  flex-basis: 20%;
}

.goal-form .form-group input {
  padding: 8px;
  border-radius: var(--btn_border_radius);
}
.goal-form .form-group textarea {
  flex-basis: 75%;
  padding: 8px;
  border-radius: var(--btn_border_radius);
}

.goal-form .form-group textarea {
  resize: vertical;
}

.form-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.form-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: var(--btn_border_radius);
  cursor: pointer;
}

.dynamic-width-input {
  display: inline-block;
  width: auto;
}

.round-button {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goal-steps-description {
  width: 100%;
  border: none;
  resize: vertical;
  border-bottom: 1px solid gainsboro;
}

.table {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.table-head {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.table-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.goal-step-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.goal-steps {
  flex: 1;
}

.goal-desc {
  flex: 3;
}

.goal-score {
  flex: 0.2;
}

.button-container {
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goal-steps-heading {
  flex: 1.5;
}

.goal-desc-heading {
  flex: 1.7;
}

.goal-score-heading {
  flex: 0.5;
  text-align: center;
}

.button-container-heading {
  flex: 0.5;
}

.add-goal-heading {
  font-size: 22px;
  font-weight: 800;
}

.add-goal-sub-heading {
  font-size: 18px;
  font-weight: 500;
}

.header {
  height: 60px;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
}
.goal-form {
  background-color: #fff;
  border-radius: var(--card_border_radius);
  padding: 20px;

  width: 100%;
  position: relative;
}

/* .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
} */

.goal-form .form-group label {
  flex-basis: 20%;
}
.goal-form .form-group textarea {
  flex-basis: 75%;
  padding: 8px;
  border-radius: var(--btn_border_radius);
}

.goal-form .form-group textarea {
  resize: vertical;
}

.goal-form table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.goal-form table th,
.goal-form table td {
  border: none;
  padding: 8px;
  text-align: left;
}

.goal-form table td {
  border-bottom: 1px solid #ddd;
  vertical-align: top;
}

.form-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.form-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: var(--btn_border_radius);
  cursor: pointer;
}

.dynamic-width-input {
  display: inline-block;
  width: auto;
}

.round-button {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-row-button {
  position: absolute;
  right: 12px;
  bottom: -43px;
}

table tbody {
  position: relative;
}

.goal-name {
  padding: 5px;
}

.goal-description {
  padding: 5px;
}

.duration-input-box-container .p-inputnumber {
  width: 70px !important;
  background-color: transparent !important;
}

.err-msg {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}

.add-goal-header {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.duration-input-box-container {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
}
.goal-name-input-box-container {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
}

.goal-step-start-toolbar {
  max-width: 61.5rem;
  word-wrap: break-word;
}

.score-input-box {
  width: 50px;
  height: 28px;
  font-size: 14px;
  margin-right: 5px;
}

/* .edit-goal-container {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
} */
@media screen and (max-width: 575px) {
  .edit-goal-container {
    display: block;
  }
}
