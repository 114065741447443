/* pro sidebar *********************************/
.ps-submenu-root > .ps-menu-button.ps-open {
  /* color: var(--blue-highlightbtn) !important; */
}
.ps-submenu-root > .ps-menu-button {
  color: white !important;
}
 
.blue .ps-submenu-root > .ps-menu-button:hover {
  color: var(--blue-highlightbtn) !important;
}
/* .blue .ps-submenu-root.ps-open > .ps-menu-button:hover {
  color: white !important;
} */
header {
  padding: 14px 0;
  text-align: left;
}
.sidebar-menu img svg{
  fill:green !important;
}
.blue .ps-menu-icon img :hover{
  fill:rgb(12, 12, 150) !important;
}
.sidebar-menu {
  /* height: 90vh; */
  height: calc(100vh - 62px); 
  overflow-y: auto;
}
.sidebar-menu::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
/* .sidebar-menu-item {
  padding: 0.8rem 0rem 0 0rem; 
  border-radius: 0 !important;
} */
.sidebar-submenu-item {
  /* padding: 0.8rem 1rem 0 1rem; */
  display: flex;
  flex-direction: column;
}
.sidebar-menu-item > a {
  /* padding: -2rem; */
  border-radius: 0 !important;
  border-left: 5px solid transparent;
}
.blue .sidebar-menu-item > a.ps-active {
  border-left: 5px solid var(--lightblue-highlightbtn);
  box-shadow: none;
}

.internal_sub_menu{
  padding-inline: 0px !important;
}
.internal_sub_menu .sidebar-submenu-contents{
  /* padding-inline: 1rem; */
}
.sidebar-submenu-contents{
  padding: 0 0.7rem 0.7rem 0.7rem;
}
.internal_sub_menu .ps-menu-icon.ps-open svg{
  /* fill: white !important; */
}

.sidebar-icon {
  width: 2.8rem;
  margin: 0.2rem 1rem;
}
.sidebar-image {
  width: 9.2rem;
  margin: 0.2rem 1.2rem;
}
.css-dip3t8 {
  background-color: transparent;
}
.css-16c54el > .ps-menu-button {
  padding-left: 8.5px !important;
}
.css-1obagno > .ps-menu-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 0.9rem !important;
}
.css-rvdqrz > .ps-menu-button {
  padding-left: 7.9px !important;
}
.css-z5rm24 {
  background-color: transparent;
}
.css-1097eso {
  display: none !important;
}
.css-erhp0f > .ps-menu-button {
  padding-left: 7.9px !important;
}
.css-af803q > .ps-menu-button {
  padding-left: 7.9px !important;
}
.sidebar-menu + ul.css-ewdv3l a:first-child li.sidebar-menu-item {
  padding-top: 10px !important;
}
.css-wx7wi4 {
  min-width: 30px !important;
}
.blue .sidebar-menu-item .ps-menu-button.ps-active{
  background-color:  var(--blue-highlightbtn) !important;
  box-shadow: 1px 1px 8px var(--blue-highlightbtn);
  color: white !important;
}
.blue .sidebar-menu-item .ps-menu-button.ps-active svg{
  fill:  var(--blue-highlightbtn) !important;
  fill:  #fff !important;
}
.blue .sidebar-submenu-item a.ps-active.ps-open{
  background-color: white !important;
  box-shadow: 1px 1px 8px #666;
  color: var(--blue-highlightbtn) !important;
}
 .ps-collapsed.main-sidebar .ps-menu-icon{
  margin-right: 0px !important;
}
.sidebar-submenu-contents .sidebar-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sidebar-submenu-contents{
  padding-inline: 0px;
}
.css-1l5fxv3 .sidebar-submenu-contents {
  padding: 3px 0 15px;
}
.ps-collapsed .ps-menu-button {
  padding-left: 22px !important;
}
.ps-collapsed .ps-menu-label {
  display: none;
}
.css-1wvake5 {
  border-right: none !important;
}
.ps-submenu-expand-icon{
  line-height: 0;
}
.pro-sidebar-container {
  max-width: 250px;
  z-index:999;
}
a.ps-menu-button.ps-active:not(.css-ewdv3l a.ps-menu-button.ps-active) {
  padding-left: 8px;
}

/*payel*/
.ps-submenu-root > .ps-menu-button.ps-open {
  /* color: var(--blue-highlightbtn) !important; */
}
.ps-submenu-root > .ps-menu-button {
  color: white !important;
}
 
.blue .ps-submenu-root > .ps-menu-button:hover {
  color: var(--blue-highlightbtn) !important;
}





/* Responsive */

@media only screen and (max-width: 1199px) {
  .navbar {
    display: none;
    position: fixed;
    left: 0;
    width: 0;
    z-index: 9;
  }

  .navbar-onclick {
    display: block;
    min-width: inherit;
    width: 233px;
    flex: 1 1;
  }

  .upper-navbar {
    /* flex-direction: row-reverse; */
     justify-content: flex-between !important;
  }

  .nav-item-name-onclick {
    display: block;
  }

  .nav-single-item-onclick {
    width: 12rem;
    box-shadow: 0 0 2px 0 #333;
  }
  .css-1wvake5, .ps-collapsed.css-1wvake5{
    width: 0 ;
    min-width: 0 ;
    left: -250px ;
  }
  .css-1wvake5.ps-collapsed {
    position: fixed;
    width: 0 !important;
    min-width: 0 !important;
  }
  .pro-sidebar-container {
    position: fixed;
  }
  .sidebar-menu {
    height:calc(100vh - 50px);
    padding-bottom: 30px;
  }
  .sidebar-icon {
    width: 1.8rem;
  }
  .css-1wvake5{
    left: 0 !important;
    width: 250px !important;
    min-width: 250px !important;
  }
}

@media only screen and (max-width: 991px) {
  .navbar {
    flex: inherit;
    width: 200px;
  }

  .navbar-onclick {
    flex: 0 1;
    width: inherit;
    min-width: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-single-item {
    gap: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .nav-single-item-onclick {
    width: 10rem;
  }

  .navbar-onclick {
    min-width: inherit;
    width: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar.navbar-onclick img.logo {
    width: 70%;
  }
}
