.admin-cred-container {
  display: flex;
  gap: 1.1rem;
  padding: 1rem 0;
}
.admin_card {
  min-height: 80vh;
}
.admin_card .p-card {
  box-shadow: none !important;
}
.subbordinate-label {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.subbordinate-label > h3 {
  font-size: 1.2rem;
}

.tab-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: auto;
  line-height: 20px;
}

.main-settings {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.offset-time {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.offset-time > h3 {
  font-size: 1.2rem;
}

.offset-time > div {
  font-size: 1.3rem;
  margin-bottom: 6.9px;
}

.p-tabmenu .p-tabmenu-nav {
  background: transparent;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--blue-highlightbtn) !important;
  border-color: var(--blue-highlightbtn) !important;
}
.light .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  background-color: #f2f2f2;
}
.dark .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  color: var(--bs-white) !important;
  border-color: var(--bs-white) !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background-color: transparent;
}

.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

@media screen and (max-width: 991px) {
  .admin-cred-container {
    flex-direction: column;
  }

  .admin-cred-container .p-inputtext {
    width: 100% !important;
  }

}

@media screen and (max-width: 767px) {
  .react-js-cron {
    justify-content: center;
  }

  .subbordinate-label {
    flex-direction: column;
    gap: 0.65rem;
    align-items: self-start;
  }
  .cron-container {
    gap: 1rem;
  }
  .subbordinate-label .p-inputnumber,  .subbordinate-label .p-button{
    width: 205px;
  }
}
@media screen and (max-width: 657px)
{
  .tab-menu .p-menuitem-text
  {
    
    font-size: 14px;
    line-height: 20px;
  }
  .tab-menu .p-tabmenu-nav 
  {
    flex-wrap: wrap;
    justify-content: center;
  }
  .tab-menu .p-tabmenu-nav .p-tabmenuitem
  {
    width: 50%;
  }
}
@media screen and (max-width: 450px)
{
  .tab-menu .p-tabmenu-nav .p-tabmenuitem
  {
    width: 100%;
  }
  .tab-menu .p-tabmenu-nav .p-tabmenuitem a
  {
    justify-content: center;
  }
  .offset-time
  {
    gap: 10px;
  }
  .offset-time h3,
  .offset-time > div
  {
    font-size: 18px !important;
  }
  .offset-time{
    flex-direction: column;
  }
}