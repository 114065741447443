.border-none {
  border: none;
}

.skill-name-formatter{
    word-wrap: break-word;
    max-width: 95px;
    overflow-wrap: break-word;
    text-align: center;
}

.user-goal-name {
  font-size: 20px;
  font-weight: 700;
}

.user-goal-description {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.score-info {
  font-size: 35px !important;
  font-weight: 800 !important;
}

.given-score {
  color: var(--blue-highlightbtn);
  font-size: 16px;
  font-weight: bold;
}

.step-description {
  padding: 6px;
  display: flex;
  justify-content: space-between;
}
