@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}
p,span,h1,h2,h3,h4,h5,h6{
  font-family: "Nunito", sans-serif;
}
.nav {
  display: flex;
  background-color: blueviolet;
  color: white;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 5rem;
}

.nav>div:nth-of-type(1) {
  font-size: 1.8rem;
}

.nav>div:nth-of-type(2)>button {
  padding: 3px 10px;
  cursor: pointer;
}

/* uncaught runtime error disable */
#webpack-dev-server-client-overlay-div,
#webpack-dev-server-client-overlay {
  display: none;
}


/* login ***************************************************/

.login {
  display: flex;
}

.left-login,
.right-login {
  width: 50%;
}

.login-image {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.left-login {
  position: relative;
}

.text-on-image-1 {
  color: white;
  position: absolute;
  font-size: 3em;
  top: 0.6rem;
  left: 1rem;
}

.text-on-image-2 {
  width: 32.1rem;
  position: absolute;
  font-size: 3.2em;
  line-height: 1.2;
  color: white;
  left: 1.1rem;
  top: 8.5rem;
}

.text-on-image-2>span {
  font-weight: bold;
}

.right-login {
  position: relative;
}

.login-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sign-in-text {
  text-align: center;
  font-weight: 300;
  font-size: 1.8rem;
  margin-bottom: 0.6rem;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
  width: 100%;
  min-width: 119px;
}

.login-inputs-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-inputs-form>input {
  border: 1px solid #d9cfcf;
  border-radius: var(--btn_border_radius);
  outline: none;
  height: 50px;
  font-size: 18px;
  padding: 9.5px;
}

.login-inputs-form>button {
  padding: 9.3px 0;
  background-color: #551fb3;
  border: none;
  border-radius: var(--btn_border_radius);
  color: white;
  cursor: pointer;
  height: 50px;
  font-size: 18px;
}

.login-inputs-form>button:hover {
  background-color: #551fb3;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-weight: bold;
}

.err-msg {
  color: #cd0404;
  font-size: 0.9rem;
  margin-top: -10px;
  position: inherit !important;
  top: 0 !important;
  left: 0;
}

.login-logo {
  margin-top: 1.2rem;
}

.invalid-input{
  border: 1px solid #cd0404;
}
@media only screen and (max-width: 1366px) {
  .text-on-image-2{
    top: 5.5rem;
    font-size: 3.0em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .apexcharts-canvas.apexchartsryphozj7.apexcharts-theme-light {
    width: 696px !important;
  }
}

@media only screen and (max-width: 991px) {
  .login {
    flex-direction: column;
  }

  .login-inputs {
    gap: 2rem;
    background: #fff;
    padding: 50px 25px;
    box-shadow: 0 0 5px 0 #0000007a;
    border-radius: 20px;
    top: inherit;
    bottom: -40px;
    width: 80%;
  }

  .left-login,
  .right-login {
    width: 100%;
  }

  .login-inputs-form {
    gap: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  /* .login-inputs {
    bottom: 0px;
  } */

  .text-on-image-2 {
    width: 31rem;
    font-size: 2.8rem;
    top: 8rem;
  }

  .sign-in-text {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 575px) {
  .login-inputs {
    bottom: 0px;
  }
  .text-on-image-2 {
    width: 90%;
    font-size: 2.5rem;
    top: 8rem;
  }
}

@media only screen and (max-width: 480px) {
  .text-on-image-2 {
    font-size: 1.8rem;
    top: 6rem;
  }
}