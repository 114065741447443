.header-performance,
.sub-ratings {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.sub-ratings .main-ratings{
  justify-content: end;
}

.sub-reviews {
  padding: 0.4rem 1.9rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.sub-reviews > span:nth-of-type(1) {
  font-weight: 500;
}

.sub-reviews > span:nth-of-type(2) {
  font-size: 15px;
  letter-spacing: 0.3px;
}
.topAccord > a {
  border-radius: var(--card_border_radius) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.topAccord > a, .topAccord.p-accordion .p-accordion-header .p-accordion-header-link{
  padding: 1rem 1rem !important;
  box-shadow: 0px 3px 12px rgba(159, 209, 255, 0.3);
  /* border: 1px solid #dee2e6 !important; */
  background: var(--bs-white) !important;
}
.performanceCont > .p-accordion-tab .topAccord {
  /* margin: 0.8rem !important; */
  border-bottom: 1px solid #ededed;
}
.topAccord.p-toggleable-content-enter-done{
  border-bottom: none !important;
}


/* .topAccord.p-accordion .p-accordion-content{
  border-color:transparent !important ;
} */
.RatingbottomPart{
  border-top: 1px solid #ededed;
}
.employee-name,
.kpi-name {
  max-width: 11rem;
}
.kpi-name {
  min-width: 7rem;
}
.employee-name{
  margin-left:0;
}

.main-ratings {
  display: flex;
  gap: 0.4rem;
  gap: 0.5rem;
  width: 8.5rem;
  width: 11rem;
  align-items: center;
  justify-content: start;
  flex-direction: row-reverse;
}

.sub-ratings {
  /* padding: 0.5px 1.25rem; */
  cursor: pointer;
}

/* .p-accordion .p-accordion-content {
  padding: 0 !important;
} */

.main-performance.performanceCont {
  /* display: grid;
  gap: 0.7rem;
  height: fit-content;
  grid-template-columns: 1fr 1fr 1fr 1fr; */
  column-count: 3;
  transition: 0.3s all ease-in-out;
}
.main-performance > .p-accordion-tab, .performanceCont > .p-accordion-tab{
  margin-bottom: 1rem !important;
  transition: 0.3s all ease-in-out;
}

.main-performance_review .p-accordion-tab{
  width: 100%;
}
.performance-tab {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  /* padding: 0.2rem 0;
  border-bottom: 1px solid #e0cece; */
}

.endToolbarPerformance {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.sortingContainer {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 1.3rem;
}

.per-view-btn {
  max-width: 80px;
  width: 100%;
  padding: 0.55rem 0.85rem !important;
  text-align: center;
  display: block;
  overflow: inherit !important;
  font-size: 0.8rem;
}
.rating-comp {
  display: flex;
  gap: 4.5px;
  margin-top: -4px;
}

.sortIcon {
  cursor: pointer;
}
.employee-top-head .employee-billing-employee-part > span{
  display: flex;
  flex: auto;
  justify-content: end;
}
.add-employe-dropdown{
  background-color: #fff;
  max-width: 205px;
}
.add-employe-dropdown .react-datepicker-wrapper{
  width: 100%;
}
.employee-custom-datepicker{
  border-radius: var(--btn_border_radius);
}
.employee-top-head .p-button.p-button-outlined {
  background-color: transparent !important;
  color: #ffffff !important;
  border: 1px solid #fff !important;
  padding: 0.2rem 0.9rem;
  height: 2rem;
}
.employee-top-head .p-button.p-button-outlined span{
  font-size: 13px;
}
/* .add-employe-dropdown .p-button{
  background: transparent;
  color: rgb(123, 69, 217);
  border-color: rgb(123, 69, 217);
  padding: 0.2rem 1rem;
  font-size: 13px;
  height: 35px;
} */
/* .add-employe-dropdown .react-datepicker__header{
    background-color: rgb(123, 69, 217);
} */
.p-accordion .p-accordion-content {
  background-color: transparent;
  border: 1px solid transparent !important;
}
/* .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: transparent !important;
  border-color: transparent !important;
  color: white !important;
} */

/*.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
   background: transparent !important;
  border-color: #dee2e6 !important;
}
.main-performance.performanceCont .p-accordion-tab-active .topAccord > a,
.main-performance.performanceCont .p-accordion-tab-active .p-accordion-content{
  background-color: #f2f2f2 !important;
} */

.p-accordion-header.p-highlight.per-head.perViewHead {
  background: var(--extralightblue) !important;
}
.reviewBtn .per-view-btn {
  background-color: #DDFEE3 !important;
  color: #12982A !important;
  border-radius: 20px !important;
  height: 25px;
  line-height: 1.2;
  padding: 3px 15px !important;
  border-color: transparent !important;
}

/* .p-accordion .p-accordion-header .p-accordion-header-link {
  background: transparent !important;
  border: none !important;
  color: white !important;
  padding: 0.6rem 1.25rem !important ;
 
} */
.main-performance_review .p-accordion-header-link{
  padding: 0 !important;
}

.per-head .p-accordion-header-link svg {
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  left: auto;
  right: 15px;
  top: 12px;
  fill: var(--highlight-text-color);
  /* background: #666; */
  border-radius: 50%;
}
@media screen and (min-width: 1600px){
  .per-head .p-accordion-header-link svg{
    right: 20px;
    top: 20px;
  }
}
.light .per-head .p-accordion-header-link svg:before {
  position: absolute;
  width: 20px;
  height: 20px;
  content:"";
  background: var(--lightblue-highlightbtn);
  z-index: 0;
}
  
.main-performance_review .per-head .p-accordion-header-link svg{
  top: 10px;
}

.date-comp {
  border-radius: unset;
}

.date-comp-prev-btn {
  border-radius: 6.9px 0 0 6.9px;
}

.date-comp-next-btn {
  border-radius: 0px 6.9px 6.9px 0;
}


.main-performance .p-accordion-tab{
  border-radius: var(--card_border_radius);
  overflow: hidden;
}
.main-performance.performanceCont .p-accordion-tab{
  /* border: 1px solid #ededed; */
  box-shadow: 0px 2px 10px rgba(159, 209, 255, 0.5) !important;
  border-radius: 13px !important;
}
.header-performance{
  width: 100%;
  gap: 0.25rem;
}
span.p-accordion-header-text {
  min-width: 100%;
}
.performance-tab .sub-ratings {
  justify-content: space-between;
  /* gap: 3rem; */
}
.performance-tab .sub-ratings .kpi-name{
  max-width: 25rem;
  text-transform: capitalize;
  font-size: 1rem;
}
.endToolbarPerformance .p-dropdown .p-dropdown-label.p-placeholder{color: #495057;}

.input-for-billing-time-main .p-inputtext{
  font-size: 0.9rem;
  padding: 4px 10px;
  text-align: center;
}
.Etool_dropdown .p-dropdown-label{
    margin-right: 12px;
  }
.Etool_dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 34px;
}

.your_performanceCont {
  display: flex;
}


/* Responsive design 1280 and 1920 ***************/
@media screen and (max-width: 3480px) {
  .header-performance,
  .sub-ratings {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 2560px) {
  /* .main-performance{
    grid-template-columns: 1fr 1fr 1fr;
  } */
}

@media screen and (max-width: 1920px) {
  /* .main-performance{
    grid-template-columns: 1fr 1fr;
  } */

  .header-performance,
  .sub-ratings {
    gap: 0.9rem;
  }

  .main-ratings {
    /* max-width: 9.7rem; */
    cursor: default;
  }

  .employee-name,
  .kpi-name {
    /* max-width: 7.3rem; */
    /* width: 100%; */
  }
}
@media screen and (max-width: 1899px) {

  .main-performance .p-accordion-header-link {
    justify-content: center !important;
  }
}
@media screen and (max-width: 1599px) {
  .per-head .p-accordion-header-link svg{
    left: auto;
    right:15px
  }
}
@media screen and (max-width: 1399px) {
  /* .per-head .p-accordion-header-link svg{
    left: 12px;
  } */
  .performance-tab{
    padding: 0;
  }
  
  /* .employee-name{
    margin-right: 10px;
    margin-left: 22px;
    font-size: 0.9rem;
  } */
  .endToolbarPerformance .p-selectbutton .p-button{
    padding: 0.75rem 0.75rem ;
  }

}

@media screen and (max-width: 1280px) {
  .main-performance.performanceCont {
    column-count: 2;
  }
  .main-ratings {
    /* max-width: 8.2rem; */
    flex-wrap: nowrap;
  }
  /* .per-view-btn {
    margin-right: -5px;
  } */

  /* .employee-name {
    max-width: 6.5rem;
    font-size: 0.8rem;
    line-height: 16px;
  } */

  .header-performance,
  .sub-ratings {
    gap: 1.1rem;
  }

  .main-performance {
    min-width: 100%;
  }
  .performance-rating {
    width: 15px;
    height: 15px;
  }
  .performance_toolbar .example-custom-input{
    min-width: inherit !important;
    width: 115px !important;
  }
}

@media screen and (max-width: 991px) {
  /* .main-performance{
    grid-template-columns: 1fr;
  } */
  .main-performance.performanceCont {
    column-count: 1;
  }
  .header-performance.col .main-ratings{
    margin-top: 0;
  }
  .main-performance.p-accordion .p-accordion-tab {
    width: 100% !important;
  }
  .main-performance .p-accordion-header-link {
    justify-content: center !important;
  }
  .performance_toolbar .example-custom-input{
    width: 100% !important;
  }
  .per-head{
    max-width: 100%;
  }
  /* .per-head .p-accordion-header-link svg {
    left: 25px;
} */
/* .employee-name{
  max-width: 11rem;
  margin-left: 42px;
} */
.header-performance{
  gap: 0.1rem;
}
.performance-tab .sub-ratings .kpi-name{
  /* margin-left: 54px; */
  max-width: 100%;
}
.performance-tab .sub-ratings{
  gap: 7rem;
}
}

@media only screen and (max-width: 767px) {
  .main-performance {
    column-count: 1;
    transition: 0.3s all ease-in-out;
  }
  .endToolbarPerformance {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  /* .per-head .p-accordion-header-link svg {
    left: 7px;
} */
.performance-tab .sub-ratings .kpi-name {
  margin-left: 3px;
}
.endToolbarPerformance{
  gap: .8rem;
}
.performance-tab .sub-ratings {
  gap: 5rem;
}
}
@media only screen and (max-width: 767px) {
  /* .employee-name {
    max-width: 9rem;
    font-size: 14px;
    line-height: 20px;
    margin-right: 3px;
} */
/* .rating-comp span, .performance-tab .sub-ratings .kpi-name{
  font-size: 21px !important;
} */
.main-ratings {
  max-width: 150px;
  justify-content: center;
}
.per-view-btn{
  /* width: 60px; */
  font-size: 0.9rem;
}
.performance-tab .sub-ratings {
  gap: 1rem;
}
/* .employee-name{
  margin-left: 18px;
} */
.date-range, .rdrCalendarWrapper{
  max-width: 100%;
}
/* .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text, .rdrDay, .rdrWeekDay, .rdrMonthName, .rdrMonthAndYearPickers select{
  font-size: 10px !important;
} */
}
@media only screen and (max-width: 580px){
  .performance-tab .sub-ratings .kpi-name{
    max-width: 240px;
    width: 100%;
    word-wrap: break-word;
  }
  .rdrWeekDay,.rdrDay{
    font-size: 0.7rem !important;
  }
}
@media only screen and (max-width: 480px) {
  /* .employee-name {
    max-width: 100%;
    font-size: 14px;
    text-align: center;
} */
.endToolbarPerformance{
  gap: 0.5rem;
}
/* .header-performance{
  flex-direction: column !important;
  gap: 0.6rem;
} */
.p-paginator .p-paginator-pages .p-paginator-page{
  min-width: 2rem;
  height: 2rem;
}
.p-paginator{
  padding: 0.4rem;
  /* margin: 20px 0; */
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text,.rdrMonthName, .rdrMonthAndYearPickers select{
  font-size: .9rem !important;
}
.performance-tab .sub-ratings .kpi-name{
  max-width: 160px;
  width: 100%;
  word-wrap: break-word;
}
}
.rating_reviewBx .employee-name{
  max-width: 100% !important;
}
.rating_reviewBx .employee-name .flex-fill{
  gap: 0.5rem;
}
@media  only screen and (min-width: 1600px){
  .rating_reviewBx .p-accordion-header-link{
    padding: 1.5rem !important;
  }
  .rating_reviewBx .RatingbottomPart{
    margin-top: 0.9rem !important;
  }
}