.edit-profile {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    align-items: center;
}
.image {
    display: flex;
    justify-content: center;
}

.image-label {
    position: relative;
}

.plus-icon {
    position: absolute;
    top: 5rem;
    left: 5.5rem;
    color: #948e8e;
    cursor: pointer;
    display: none;
}

.image-label:hover .plus-icon{
    display: block;
}

.profile-pic {
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 50%;
    object-fit: contain;
    cursor: pointer;
}

.usernames {
    display: flex;
    gap: 2rem;
}

.input-label>input {
    width: 17.5rem;
}

.input-label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.email-add>input,
.emp-id>input,
.job-title>input,
.phn-num>input {
    width: 37.3rem;
}

.edit-prof-save-btn {
    padding: 0.55rem 1.25rem;
    border: none;
}


@media only screen and (max-width:991px){
    .edit-profile .input-label>input {
        width: 15.5rem;
    }
    .edit-profile .email-add>input, .edit-profile .emp-id>input, .edit-profile .job-title>input, .edit-profile .phn-num>input {
        width: 33rem;
    }
    .edit-prof-save-btn {
        padding:0.55rem 1.25rem !important;
    }
}
@media only screen and (max-width:767px){
    .usernames{
        flex-direction: column;
        width: 100%;
    }
    .edit-profile .input-label>input {
        width: 15rem;
    }
    .edit-profile .email-add>input, .edit-profile .emp-id>input, .edit-profile .job-title>input, .edit-profile .phn-num>input {
        width: 15rem;
    }
    .edit-profile{
        padding: 0;
    }
    .input-label{
        width: 100%;

    }
    .input-label input{
        width: 100% !important;
    }
}
@media only screen and (max-width:575px){
    .edit-profile .input-label>input {
        width: 100%;
    }
    .edit-profile .email-add>input, .edit-profile .emp-id>input, .edit-profile .job-title>input, .edit-profile .phn-num>input {
        width: 100%;
    }
    .edit-profile>h2 {
        font-size: 1.5rem;
    }
    .edit-profile .profile-pic {
        width: 8.5rem;
        height: 8.5rem;
    }
    .edit-profile .input-label{
        font-size: 13px;
    }
}
@media only screen and (max-width:480px){
    .edit-profile>h2 {
        font-size: 1.2rem;
    }

}