:root {
  /* --body-fontsize: 0.9rem !important;
  --input_height_40: 35px !important;

  --blue-highlightbtn: #0355A0 !important;
  --blue-highlightbtn-hover: #0D72CF !important;
  --lightblue-highlightbtn: #5BADF9 !important;
  --lightblue-highlightbtn-hover: #3593EA !important;


  --violet-highlightbtn: rgb(85, 31, 179) !important;
  --violet-highlightbtn-hover: #7b45d9 !important;
  --lightviolet-highlightbtn: rgb(85, 31, 179) !important;
  --lightviolet-highlightbtn-hover: #7b45d9 !important;

  --green-highlightbtn: #407053 !important;
  --green-highlightbtn-hover: #5CA177 !important;
  --lightgreen-highlightbtn: #407053 !important;
  --lightgreen-highlightbtn-hover: #5CA177 !important;

  --maroon-highlightbtn: #652524 !important;
  --maroon-highlightbtn-hover: #9B4444 !important;
  --lightmaroon-highlightbtn: #9B4444 !important;
  --lightmaroon-highlightbtn-hover: #652524 !important; */
}

.main-dashboard .p-card-body,
.settings-card .p-card-body,
.proj-bill-time-report .p-card-body,
.proj-bill-time-comp-report .p-card-body,
.performance-report-card-cont1 .p-card-body
{
  padding: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.proj-bill-time-report .p-card-title,
.proj-bill-time-comp-report .p-card-title,
.main-dashboard .p-card-title,
.settings-card .p-card-title,
.performance-report-card-cont .p-card-title
{
  font-size: 1.1rem;
  /* border-bottom: 1px solid #9999994c; */
  padding:1rem 0;
  margin-bottom: 0;
}
.main-dashboard .p-card-content,
.proj-bill-time-comp-report .p-card-content,
.proj-bill-time-report .p-card-content,
.settings-card .p-card-content,
.performance-report-card-cont .p-card-content
{
  padding:0;
}
.top-billing-emp {
  height: 100%;
}

.dashboard-card-head {
  font-size: 1.1rem;
  margin-bottom: 0;
}
.top_performing_empCont {
  max-height: 338px;
  overflow-y: auto;
}
.top-performing-emp-box {
  /* display: flex;
  gap: 1rem;
  align-items: start; */
}

.dashboard-emp {
  width: 100%;
  flex-grow: 1;
}

.employees-picture {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employees-picture>img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border: 1px solid #c7c7c7;
  border-radius: 50%;
}
.only-one-user {
  width: 100%;
}

.total-times {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  border-radius: var(--card_border_radius);
}

.total-times ul {
  margin-bottom: 2px;
  padding-left: 1.5rem;
  list-style-type: none;
  padding-left: 0;
}
.total-times ul li{
  padding: 10px 20px;
  padding-left: 35px;
  border-radius: var(--card_border_radius);
  position: relative;
}
.light .total-times ul li{
  background: #fff;
  box-shadow: 0 2px 5px #ededed;
}
.dark .total-times ul li{
  background: #071426;
  box-shadow: 0 2px 5px #000;
}
.total-times ul li:before{
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: 13px;
  top: 15px;
  background-color: var(--blue-highlightbtn);
}

.react-datepicker__input-container input[type=text] {
  height: 40px;
  text-align: center;
  border: 0;
  width: 100%;
}

.react-datepicker__input-container input[type=text]:focus-visible {
  outline: none;
}

/* .react-datepicker__input-container input[type=text]:hover {
  border-left-color: #ced4da;
  border-right-color: #ced4da;
} */

.react-datepicker {
  box-shadow: 0 3px 7px 0px #999;
  border: none;
  border-radius: var(--btn_border_radius);
  overflow: hidden;
}

.dark .rdrCalendarWrapper {
  background-color: #1b2531 !important;
}

.dark .rdrDateDisplayWrapper {
  background-color: #1b2531 !important;
  color: white !important;
  border-color: white !important;
}

.dark .rdrDateDisplayItemActive {
  border-color: white !important;
}

.dark .rdrDateDisplayItemActive input {
  color: white !important;
}

.dark .rdrMonthAndYearPickers select {
  color: white !important;
}

.dark .rdrDateDisplayItem {
  background-color: #1b2531 !important;
}

.dark .rdrDateDisplayItem input {
  color: white !important;
}

.dark .rdrDateDisplayItem {
  border-color: white !important;
}

.dark .rdrDayNumber span {
  color: rgb(178, 178, 178) !important;
}

.dark .react-datepicker__current-month {
  color: white !important;
}

.dark .react-datepicker__navigation-icon::before {
  border-color: white !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #ffffff;
  top: 12px;
}

.dark .react-datepicker__header {
  background-color: #1b2531 !important;
  color: white !important;
}

.dark .react-datepicker {
  background-color: #1b2531 !important;
  color: white !important;
}

.dark .react-datepicker__navigation {
  color: white !important;
}

.react-datepicker__header, .react-datepicker__header i {
  /* padding-top: 18px; */
  color: #fff !important;
}
.light .yearSelect select{
  background-color: var(--extralightblue);
  border-color:var(--extralightblue) ;
}
.dateClose{
  display: flex;
  padding: 5px;
  position: absolute; 
  top: 5px;
  right: 20px;
  cursor: pointer
}


/* theme change style..................  */
/* body .violet .react-datepicker__header
{
  background-color: var(--lightviolet-highlightbtn);
}
body .green .react-datepicker__header{
  background-color: var(--green-highlightbtn-hover);
}
body .maroon .react-datepicker__header{
  background-color: var(--lightmaroon-highlightbtn-hover);
}
.dialog .p-selectbutton .p-button.p-highlight{
  border: 2px solid var(--lightviolet-highlightbtn);
  background: var(--lightviolet-highlightbtn);
}

body .violet .p-dropdown:not(.p-disabled):hover,
body .violet .p-dropdown:not(.p-disabled).p-focus,
body .violet .p-multiselect:not(.p-disabled):hover,
body .violet .p-inputtext:enabled:hover,
body .violet .p-inputtext:enabled:focus,
body .violet .react-datepicker__input-container input[type=text]:hover 
{
  border-color: var(--lightviolet-highlightbtn);
}
body .green .p-dropdown:not(.p-disabled):hover,
body .green .p-dropdown:not(.p-disabled).p-focus,
body .green .p-multiselect:not(.p-disabled):hover,
body .green .p-inputtext:enabled:hover,
body .green .p-inputtext:enabled:focus,
body .green .react-datepicker__input-container input[type=text]:hover 
{
  border-color: var(--green-highlightbtn-hover);
}
body .maroon .p-dropdown:not(.p-disabled):hover,
body .maroon .p-dropdown:not(.p-disabled).p-focus,
body .maroon .p-multiselect:not(.p-disabled):hover,
body .maroon .p-inputtext:enabled:hover,
body .maroon .p-inputtext:enabled:focus,
body .maroon .react-datepicker__input-container input[type=text]:hover 
{
  border-color: var(--lightmaroon-highlightbtn-hover);
} */

.react-datepicker__navigation {
  background: transparent;
  filter: drop-shadow(0px 6px 10px #cccccca6);
  border-radius: var(--btn_border_radius);
  height: 30px;
  width: 30px;
  /* top: 12px; */
  top: 4px;
  border: 1px solid #cccccca6;
  -webkit-filter: drop-shadow(0px 6px 10px #cccccca6);
}

.rdrNextPrevButton {
  filter: drop-shadow(0px 6px 10px #cccccca6);
  background: #fff;
}

.rdrCalendarWrapper {
  filter: drop-shadow(0px 6px 10px #cccccca6);
  border-radius: var(--card_border_radius);
  overflow: hidden;
}

.viewAllBtn {
  width: 70px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-weight: lighter;
  border-radius: var(--btn_border_radius);
  border: 1px solid var(--lightblue-highlightbtn);
  color: var(--blue-highlightbtn);
}

.viewAllBtn:hover{
  font-weight: lighter;
}

.react-datepicker__navigation-icon--previous::before {
  right: -5px;
}

.react-datepicker__navigation-icon--next::before {
  left: -5px;
}

.react-datepicker__navigation--previous {
  left: 11px;
}

.react-datepicker__navigation--next {
  right: 11px;
}

.react-datepicker__day-names {
  margin-top: 8px;
}

.react-datepicker__day-name,.react-datepicker__current-month,.react-datepicker__navigation-icon {
  color: #ffffff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 15px;
  width: 1.9rem;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text,
.rdrDay,
.rdrWeekDay,
.rdrMonthName,
.rdrMonthAndYearPickers select {
  font-size: 15px;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
  text-align: center;
}

.p-calendar .p-inputtext {
  text-align: center;
}

/* .example-custom-input {
  height: 51px;
  text-align: center;
  border: 1px solid #ced4da;
  line-height: 48px;
  min-width: 200px;
  background: #fff;
  color: #000;
} */
.example-custom-input {
  height: 40px;
  text-align: center;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pr_id_2 {
  top: 50% !important;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.emp-per-chart-box {
  margin-top: -42px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.emp-per-chart-box .list_item{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 300px;
  min-height: 280px;
  max-height: 358px; */
  padding-right: 10px;
  overflow-y: auto;
  border-radius: var(--card_border_radius);
}

.emp-per-chart-box h3 {
  margin-bottom: 1rem;
  position: relative;
  top: -15px;
}

.emp-per-chart-box ul {
  list-style-type: none;
  padding: 5px 10px;
  /* border: 1px solid #ccc; */
  min-height: 280px;
  max-height: 325px;
  margin-bottom: 0;
  padding-right: 10px;
  overflow-y: auto;
  border-radius: var(--card_border_radius);
}
.emp-per-chart-box ul li{
  margin-bottom: 20px;
}

.emp-per-chart-data {
  /* display: flex;
  justify-content: space-between;
  align-items: start; */
}

.emp-per-chart-data>div>p>span {
  font-weight: 500;
}

/* chart css(your oerformance chart) */
.apexcharts-legend {
  position: static;
}


.emp-performance-chart {
  display: flex;
  gap: 15px;
}

.no_employee {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border: 1px solid #ededed;
  border-radius: var(--card_border_radius);
  margin-top: 10px;
  width: 100%;
  padding: 0.5rem;
}

.emp-performance-no-data {
  width: 50%;
  text-align: center;
  padding: 170px 100px;
  border: 1px solid gainsboro;
  border-radius: 15px;
}
.apexcharts-menu-icon svg, .apexcharts-reset-icon, .apexcharts-zoom-icon {
  padding-top: 0;
}

.employeeSkillSingle{
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: var(--card_border_radius);
  box-shadow: 1px 0 7px #ededed;
}
.skillEmployeeSngl{
  padding: 10px;
  display: inline-flex;
  border-radius: var(--card_border_radius);
}

.light .skillEmployeeSngl{
  background: #fff;
}
.dark .skillEmployeeSngl{
  background: #071426;
  color: #fff;
}
.skill-badge-container{
  /* display:flex;
  flex-wrap: wrap;
  gap:35px;
  padding:10px; */
}
.skill-badge-container {
  max-height: 325px;
  overflow: auto;
}
.avatar-container{
  /* display: flex;
   flex-direction: column;
   width:70px;
   align-items: center; */
}
.skill-text{
  /* text-align:center;
  word-wrap:break-word;
  width: 5rem; */
}
.avatar-content{
  /* margin: 10px;
  width: max-content;
  padding: 10px;
  color: #000; */
  opacity: 0.5;
}

.empPerformanceChartBx{
  height: 210px;
  padding: 10px 0px;
  margin-top: 0;
}



/*=======Responsive=========*/

@media screen and (max-width: 2560px) {
  .top-performing-emp {
    width: 25.6%;
  }

}

@media screen and (max-width: 1920px) {
  /* .top-performing-emp {
    width: 49.7%;
  } */
  .empPerformanceChartBx{
    height: 335px;
  }
}

@media (min-width:992px) and (max-width: 1399px) {
  .main-dashboard .top-performing-emp-box {
    gap: 10px;
    flex-wrap: wrap;
  }

  .main-dashboard .dashboard-rating .rating-comp {
    gap: 2.5px;
  }

  .main-dashboard .dashboard-rating .rating-comp span {
    font-size: 20px !important;
  }

  .main-dashboard .top-performing-emp-box .dashboard-emp {
    width: auto;
  }

  .main-dashboard .dashboard-rating {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
  }

  .dashboard-card-head {
    font-size: 1.1rem;
  }
}

@media (min-width:1065px) and (max-width: 1280px) {
  .main-dashboard .top-performing-emp-box .dashboard-emp {
    width: auto;
  }
}

@media screen and (max-width: 1280px) {
  .growing-container>img {
    width: 40% !important;
  }

  .dashboard-emp p {
    font-size: 15px;
  }

  .emp-per-chart-box .list_item{
    max-height: 358px;
  }
  .mail-template-dialog{
    width: 55% !important;
}
}
@media screen and (max-width: 1199px) {
  .sidebar-open .performance-table .p-datatable-wrapper,
  .sidebar-close .performance-table .p-datatable-wrapper
  {
    width:auto !important;
  }
  .audit-log-table .p-datatable-wrapper{
    max-width: calc(100vw - 55px);
  }
  .kpiTable .p-datatable-wrapper{
    max-width: calc(100vw - 45px);
  }
}
@media screen and (max-width: 991px) {

  .top-performing-emp-admin,
  .top-billing-emp-admin {
    width: 49%;
    margin: 0 auto;
  }
  .emp-per-chart-box .list_item{
    max-width: inherit;
  }

  /* .top-billing-emp,
  .top-billing-proj,
  .user-performance-chart,
  .top-tracking-proj {
    width: 49.40%;
  } */
  .mail-template-dialog table th,
.mail-template-dialog table td
{
  padding: 0.8rem 2rem;
}
}

@media screen and (max-width: 767px) {
  /* .date-input .p-button.p-button-icon-only {
    height: 52px;
  } */
  .emp-per-chart-box{
    margin-top: 15px;
  }
  .emp-performance-chart{
    flex-direction: column;
    align-items: center;
  }
  .apexcharts-canvas svg{
    border: none;
  }
  .dashboard_sec.p-toolbar.p-component {
    justify-content: flex-start;
    align-items: center;
    gap: 0 !important;
  }

  .date-input {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-dashboard .top-performing-emp-box {
    gap: 10px;
    flex-wrap: wrap;
  }

  .main-dashboard .dashboard-rating .rating-comp {
    gap: 2.5px;
  }

  .main-dashboard .dashboard-rating .rating-comp span {
    font-size: 20px !important;
  }

  .main-dashboard .top-performing-emp-box .dashboard-emp {
    width: 11rem;
  }

  .main-dashboard .dashboard-rating {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 15px;
  }

  .dashboard-card-head {
    font-size: 1.1rem;
  }
  .emp-per-chart-box .list_item{
    max-height: 358px;
    min-height: 0;
  }
  .mail-template-dialog{
    width: 80% !important;
}
.emp-per-chart-box h3{
  top: auto;
  margin-bottom: 20px;
}
}

@media screen and (max-width: 575px) {

  .top-performing-emp-admin,
  .top-billing-emp-admin,
  .top-billing-proj,
  .top-tracking-proj {
    width: 100%;
    margin: 0 auto;
  }

  .top-billing-emp,
  .top-billing-proj,
  .top-tracking-proj,
  .user-performance-chart {
    width: 100%;
  }

  .date-input .p-button {
    padding: 6px;
    font-size: 14px;
  }

  .date-input .p-button.p-button-icon-only {
    /* height: 52px; */
    padding: 0;
  }

  .example-custom-input {
    /* min-width: 100px; */
    padding: 0 5px;
    /* height: 52px; */
  }

  .main-dashboard .dashboard-rating {
    width: inherit !important;
    display: inline-block;
  }

  .main-dashboard .top-performing-emp-box .dashboard-emp {
    width: 14rem;
  }

  .main-dashboard .top-performing-emp-box {
    gap: 20px;
    flex-wrap: wrap;
  }
  .growing-container {
    flex-direction: column;
  }

  .growing-container img {
    width: 60% !important;
  }
  /* .audit-log-table .p-datatable-wrapper{
    max-width: calc(100vw - 60px);
  }
  .kpiTable .p-datatable-wrapper{
    max-width: calc(100vw - 40px);
  } */
}


@media screen and (max-width: 480px) {
  .report-start-tool {
    flex-direction: column;
    /* align-items: center; */
  }

  .main-dashboard .dashboard-rating {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }


  .main-dashboard .top-performing-emp-box .dashboard-emp {
    width: 11rem;
  }

  .main-dashboard .top-performing-emp-box {
    gap: 10px;
  }

  .dashboard_sec .report-start-tool {
    align-items: baseline;
  }

  .example-custom-input {
    min-width: 150px !important;
  }
  /* .audit-log-table .p-datatable-wrapper{
    max-width: calc(100vw - 45px);
  }
  .kpiTable .p-datatable-wrapper{
    max-width: calc(100vw - 27px);
  } */
  /* .mail-template-save-btn{
    margin-top: 0.5rem;
  } */
  .proj-settings-btn{
    padding-bottom: 0.6rem !important;
  }
  .edit-profile h2{
    padding-top: 0.6rem;
  }
  }