.report {
    display: flex;
    flex-direction: column;
    gap: 32%;
}
.report-datatable{
    overflow: hidden !important;
}
.report-start-tool {
    display: flex;
    gap: 1rem;
    position: relative;
}

.excel-btn {
    background-color: #1d7044 !important;
    border: #1d7044 !important;
}

.pdf-btn {
    background-color: #cd0e0e !important;
    border: #cd0e0e !important;
}

.endToolReport {
    gap: 0.5rem;
}

.date-range {
    position: absolute;
    top: 105%;
    left: 0;
    z-index: 999;
}

.calender {
    border-radius: 0px;
}

.date-arrow {
    border-radius: 0px;
}

.p-button-label {
    flex: none;
}

.date-input {
    display: flex;
    height: fit-content;
}

.report-charts {
    /* display: flex;
    flex-wrap: wrap; */
    /* gap: 0.3rem;
    margin-top: 0.3rem; */
    justify-content: space-between;
}
.report-charts .p-card-content .dashbord-card-cont{
    padding: 0 1rem;
}

/* .proj-bill-time-report {
    width: 49.5%;
} */

.p-datatable-emptymessage {
    background: transparent !important;
}

.p-datatable-emptymessage td {
    color: #928989;
}

.proj-bill-time-comp-report {
    width: 100%;
    /* margin: 6px 0; */
}

.custom_date_range {
    display: flex;
    align-items: center;
    border: 1px solid var(--blue-highlightbtn);
    padding: 0 39.7px;
    border-radius: 6px;
}
.apexcharts-treemap-rect{
    width: 100%;
}
.apexcharts-menu{
    background: transparent !important;
    backdrop-filter: brightness(0) !important;
    color: #fff !important;
}
.apexcharts-menu-item:hover{
    color: #000 !important;
    background-color: #fff;
}
.apexcharts-menu-icon:hover svg{
    fill: #ccc !important;
}
.report .p-datatable>.p-datatable-wrapper{
    height: auto !important;
}
.report .p-datatable .p-datatable-wrapper{
    max-height: calc(100vh - 400px);
}
.report-charts .apexcharts-canvas svg{
    border: none;
}
.proj-bill-time-comp-report .p-card-body, .proj-bill-time-report .p-card-body{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.proj-bill-time-comp-report .p-card-title, .proj-bill-time-report .p-card-title{
    background-color: var(--extralightblue);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

@media screen and (max-width:991px) {

    .report .p-toolbar-group-right,
    .report .endToolbarPerformance {
        width: 100%;
    }

    .report .p-multiselect-label {
        width: 550px;
    }
    .p-button
    {
        padding: 0.75rem 1rem;
    }
}

@media screen and (max-width:767px) {
    /* .report .p-button {
        padding: 0.75rem 0.85rem;
    } */

    .report .endToolbarPerformance {
        justify-content: start !important;
    }
    .p-toolbar-group-end.p-toolbar-group-right{
        width: 100%;
    }
    .report_toolbar{
        width: 100%;
    }
    .proj-bill-time-report {
        width: 100%;
    }

    .proj-bill-time-comp-report {
        margin: 0;
    }

    .report .p-multiselect-label {
        width: 440px;
    }
    .p-button {
        padding: 0.70rem 0.4rem;
    }
    .report_date_range .rdrCalendarWrapper{
        max-width: 500px;
    }
}

@media screen and (max-width:680px) {
    .report-start-tool {
        flex-direction: column;
    }

    .report .date-input {
        justify-content: start;
    }
   
}

@media screen and (max-width:575px) {

    .report .p-toolbar-group-left {
        width: 100% !important;
        justify-content: start;
    }

    .report-start-tool .date-input {
        justify-content: stretch;
    }

    .report .p-multiselect-label {
        width: 340px;
    }
    .report_date_range .rdrCalendarWrapper{
        max-width: 430px;
    }
}

@media screen and (max-width:480px) {

    .report .report-start-tool {
        align-items: start;
    }

    .report .p-multiselect-label {
        width: 250px;
    }

    .report .p-selectbutton .p-button {
        font-size: 14px;
        padding: 0.75rem 0.85rem;
    }
    /* .p-datatable .p-column-header-content{
        font-size: 0.7rem;
    } */
    .report-datatable .p-datatable-thead>tr>th{
        padding: 0.5rem 0.5rem !important;
    }
    .report-datatable .p-datatable-thead>tr>th .p-column-header-content
    {
        font-size: 1rem !important;
    }
    .report_date_range .rdrCalendarWrapper{
        max-width: 350px;
    }
}
.parent-sel-btn>div {
    padding: 0 ;
    margin: 1px !important;
}
.parent-sel-btn>div:first-child {
    margin-left: 0 !important;
}
.parent-sel-btn>div:last-child {
    margin-right: 0 !important;
}
.selBtn {
    padding: 0.8rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
    color: inherit !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus{
    box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight, .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover{
    background: transparent;
}