.audit-endtoolbar{
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    width: 100%;
    /* padding: 0px 20px; */
}

/* .upper-end-toolbar {
    display: flex;
    gap: 10px;
    align-items: center;
} */

.show-filters {
    color: #6e6d6d;
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 6px 10px;
    border: 1px solid #999;
    border-radius: var(--btn_border_radius);
}
.calenderAbsIcon{
    position: absolute;
    left: 10px;
    top: 6px;
}

/* .audit-daterange{
    margin: 8px 10px -50px !important;
    position: absolute;
    top: 0;
    z-index: 99;
} */
@media screen and (max-width:768px) {
   .audit-daterange{
    margin: 8px !important;
    position: relative;
   }
}
@media screen and (max-width:480px) {
    .lower-end-toolbar input[type="search"] {
        width: 100% !important;
    }
}
