.container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.expanded-dots {
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
}

.done-mark {
  color: green;
  margin-left: 2px;
}

.done-check-mark {
  color: white;
  background-color: green;
  border-radius: 50%;
  padding: 2px;
}

.align-at-end {
  display: flex;
  justify-content: flex-end;
}

.align-in-between {
  display: flex;
  justify-content: space-between;
}

.card {
  padding: 10px;
  position: relative;
}

.card.done {
  border-color: green;
}
.done-badge {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: green;
  color: white;
  padding: 5px;
  border-radius: var(--btn_border_radius);
}
.goal_card p span {
  margin: 0 auto;
  line-height: 1.3;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.goal_card{
  height: 100%;
  background: var(--bs-white) !important;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: var(--card_border_radius) !important;
  border: 1px solid rgba(188, 188, 188, 0.374);
}
.goal_card .p-card-title {
  /* height: 2.5rem; */
  font-size: 18px;
  line-height: 1.1;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* border-bottom: 1px solid var(--bs-white); */
  border-bottom: 1px solid var(--bs-gray-300);
  padding-bottom: 10px;
}

.dark ::ng-deep .p-dialog-footer {
  background-color: rgb(39, 49, 67) !important;
  color: white !important;
}
.userGoalSingle:first-child{
  /* padding-left: 0 !important; */
}
.userGoalSingle:nth-last-of-type(1){
  padding-right: 0 !important;
}
.user_goal_dialog {
  width: 90% !important;
}

.goal_popup_info h1 {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 0;
}
.goal_popup_info P {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 35px;
  margin: 0 auto;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.toal_done_work {
  display: flex;
  background-color: var(--bs-white);
  height: 28px;
  line-height: 18px;
  padding: 12px 15px;
  margin-right: 10px;
  gap: 5px;
  border-radius: var(--btn_border_radius);
}
.toal_done_work span {
  font-size: 14px;
  line-height: 13px;
  font-weight: 800;
}
.ug_headding_title {
  padding: 6px 13px;
  font-weight: 700;
  display: flex;
  gap: 5px;
  margin-left: 10px;
  font-size: 15px;
  color: #273547;
  border: 1px solid #ccc;
  border-radius: var(--btn_border_radius);
}


@media screen and (max-width: 1200px) {
  .user_goal_dialog {
    width: 90% !important;
  }
}
@media (min-width: 1201px) {
  .user_goal_dialog {
    width: 40% !important;
  }
}

/* @media screen and (max-width: 1200px) {
  .user_goal_dialog {
    width: 90% !important;
  }
} */
