.upper-navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0.5rem 1rem; */
    padding: 1rem 1.5rem;
    border-radius: 20px;
}

.right-upper-navbar {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.upper-navbar-icon-bar {
    /* color: white; */
    cursor: pointer;
}


.p-dropdown .p-dropdown-trigger {
    color: transparent;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
}

.p-inputtext {
    /* color: white; */
}

.upper-navbar-language-dropdown {
    background-color: transparent;
    border: none;
}

.upper-navbar-user {
    background-color: transparent;
    border: none;
    text-transform: capitalize;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 11.1px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.user-dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
}

.user-dropdown-container>button {
    background-color: transparent;
    border: none;
    display: flex;
    gap: 7.6px;
    font-size: 0.9rem;
    cursor: pointer;
    align-items: center;
    /* color: #f3f4f7; */
}

.theme-colors {
    display: flex;
    gap: 0.5rem;
}
.avatarPic{
    width: 2.5rem;
    height: 2.5rem;
}
.upper-navbar-avatar {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}
.upper-navbar-avatar img{
    object-fit: cover;
}

.theme-button {
    width: 0rem;
    height: 2.3rem;
    border: none;
}

.upper-navbar-changemode-switch-button-div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 12.3px;
}
.selectBtn .p-button{
    border-radius: var(--btn_border_radius) !important;
    margin-right: 6px;
    line-height: 1;
}
.selectBtn .p-button:nth-last-of-type(1){
    margin-right: 0px;
}
.p-overlaypanel {
    /* color: white; */
    background-color: #1b2531;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-toolbar-group-start.p-toolbar-group-left,
.p-toolbar-group-end.p-toolbar-group-right{
    padding: 7px 0;
    border-radius: 10px;
    /* border: 1px solid var(--blue-highlightbtn); */
}

.p-overlaypanel:before {
    border-bottom-color: #1b2531;
}

.p-overlaypanel:after {
    border-bottom-color: #1b2531;
}

.p-inputswitch {
    width: 2.7rem;
    height: 1.35rem;
}

.p-inputswitch .p-inputswitch-slider:before {
    margin-top: -0.525rem;
}

.p-inputswitch .p-inputswitch-slider:before {
    width: 1.05rem;
    height: 1.05rem;
}

/* .p-inputtext {
    color: white;
} */

.lock {
    width: 108%;
}

.change-pwd {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.chang-pwd-input {
    width: 19rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    margin-top: 8px;
}

.change-pwd-btn {
    width: 100%;
    border: none;
}

.avatarPic img{
    border: 5px solid #ededed;
}

.flag-container {
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: space-between;
    margin-right: 7px;
}
.flag-container span{
    order: 2;
}
.flag-container svg{
    width: 18px;
}

/* .p-dialog .p-dialog-header {
    background: transparent;
}

.p-dialog .p-dialog-content {
    background: transparent;
} */

.p-overlaypanel:before {
    border-bottom-color: transparent;
}

.p-overlaypanel:after {
    border-bottom-color: transparent;
}

.loading-bar {
    position: absolute;
    left: 50%;
    top: 50vh;
    z-index: 5000000;
    transform: translate(-50%, -50%);
}


  
.p-progress-spinner{
    width: 80px;
    height: 80px;
}

/* .left-upper-navbar{
    position : relative;
} */

.upper-navbar-language .p-dropdown .p-dropdown-trigger {
    width: 1rem;
}
/* .upper-navbar-language-dropdown .p-inputtext{
    color: #fff !important;
} */
/* @media only screen and (max-width:1199px) {
    .left-upper-navbar {
        margin: 0 20px 0 35px;
    }
} */
/* 
@media only screen and (max-width:480px) {
    .left-upper-navbar {
        margin: 0 0px 0 18px;
    }
} */