/* .main-your-performance-card {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
} */
.main-your-performance-card h3{
    text-transform: capitalize;
    padding-top: 10px;
    word-break: break-all;
    text-align: center;
}
.your-performance-card {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    height: 100%;
}

.performance-container {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.growing-container {
    display: flex;
    /* justify-content: space-between; */
    gap: 2rem;
    align-items: center;
    padding: 5px;
    border-radius: var(--card_border_radius);
    padding: 1.25rem;
    border: 1px solid #dee2e6;
}

.growing-container>img {
    width: 200px;
}

.comment {
    text-align: center;
    width: fit-content;
    min-height: 100px;
    word-break: break-all;
}

.chart-container {
    position: relative;
}

.rating {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: 500;
    transform: translate(-50%, -50%);
}

.kpi-info-btn {
    width: 100%;
    display: inline-block;
    line-height: 8px;
}

.read-more{
    color: blue;
    font-size: 14.4px;
    cursor: pointer;
}
@media only screen and (max-width:767px) {
    .main-your-performance-card{
        grid-template-columns: 1fr 1fr;
    }
    .your-performance-card .p-card-body{
        padding: 1.5rem;
    }
}
@media only screen and (max-width:575px) {
    .main-your-performance-card{
        grid-template-columns: 1fr;
    }
    .performance-container {
        padding-bottom: 10px;
    }
    .main-your-performance-card{
        margin-bottom: 50px;
    }
}