.card-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 18px;
  padding: 10px;
  /* box-sizing: border-box; */
}

.p-card-title {
  font-size: 1px important !;
}

.card-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.light-gray {
  color: grey;
}

hr {
  width: 100%;
  color: gray;
  margin-top: 2px;
}

.card-item-footer {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.card-item-footer svg{
  padding: 4px 0;
  width: 22px;
  border-radius: var(--btn_border_radius);
  background: rgb(220, 238, 255);
  color: var(--blue-highlightbtn);
}

.card-item-footer span {
  font-size: 14px;
  font-weight: 700;
}

.assign_popup .p-paginator .p-paginator-current {
  margin: 0px;
}

.assign_popup .p-paginator button {
  border: 1px solid #ccc !important;
  min-width: auto;
  width: 2.5rem;
  height: 2.5rem;
}

.assigned_Employees .p-dialog-content {
  padding: 0.8rem 1.5rem !important;
}

.down-arrow-icon {
  cursor: pointer;
}

.duration-block {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 0px;
  border: 1px solid transparent;
}
.light .duration-block {
  background-color: #ebf5ff;
}
.duration-block:hover {
  border: 1px solid var(--blue-highlightbtn);
}
.dark .duration-block {
  background-color: rgb(39, 49, 67);
}

/* .duration-block span {
  font-size: 13px;
  color: rgb(133, 128, 128);
}

.duration-icon {  
  color: rgb(133, 128, 128);
} */


.no-header-dialog .p-dialog-header {
  display: none;
}

.custom-dialog {
  height: 50vh;
}

/* .custom-dialog table th tr{
  padding-bottom: 20px;
} */

.custom-dialog table {
  height: 60%;
  border-collapse: collapse;
  /* Ensure borders are collapsed */
}

.done-icon {
  color: green;
  /* Set color for Done status icon */
  margin-right: 5px;
  /* Add margin between icon and text */
}

.ongoing-icon {
  color: #bada55;
  /* Set color for Ongoing status icon */
  margin-right: 5px;
  /* Add margin between icon and text */
}

.done {
  color: green;
}

.ongoing {
  color: #bada55;
}

.assign_Goals .p-dialog-header {
  align-items: center;
  justify-content: center;
}

.add_New_Goal .p-dialog-header {
  align-items: center;
  justify-content: center;
}

.show_desc {
  color: blue;
  fontSize: 12px;
  marginLeft: 2px;

}

.expanded-dots {
  font-size: 19px;
  cursor: pointer;
}

.expanded-dots-for-goals{
  cursor: pointer;
   width: 100%;
   font-weight: 700;
   letter-spacing: 3px;
   font-size: 14px;
}

.expanded-dots-for-goals-description{
  font-weight: 700;
   letter-spacing: 3px;
   font-size: 20px;
}

.deleted-mark{
  display: flex !important;
  align-items: center !important;
  color: red !important;
  font-size: 12px !important;
}

::ng-deep .goal_card  .p-card-content{
  padding-top: 0;
}
.goal-card-description{
  display: block;
  cursor: pointer;
  width: 100%;
  min-height: 2.9rem;
  padding-top: 1rem;
  /* border-top: 1px solid var(--blue-highlightbtn); */
}

.assign-goal-footer-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width:1199px) {
  .audit-log-table.goalTable .p-datatable-wrapper {
    max-width: calc(100vw - 40px) !important;
  }
}

@media screen and (max-width:992px) {

  .assigned_Employees,
  .add_New_Goal {
    width: 90% !important;
  }

  .assign_Goals {
    width: 70% !important;
  }
}

@media screen and (max-width:768px) {

  .update_Goal,
  .assign_Goals {
    width: 90% !important;
  }
}