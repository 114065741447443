.button-container {
  display: flex;
  justify-content: flex-end;
}

input:invalid[focused="true"] {
  border: 1px solid red;
}

.status-button {
  width: 125px !important;
  font-size: 10px !important;
  text-align: center !important;
}

.status-button-container .p-button {
  width: 50%;
  text-align: center;
  font-size: 11px;
}

.subKPIBtn{
  height: 30px;
  background-color: var(--blue-highlightbtn) !important;
}
.subkpi_delete_btn{
  display: flex;
  justify-content: center;
}
.subkpi_delete_btn button{
  background-color: transparent !important;
  color: rgb(216, 3, 0) !important;
  border-color:transparent !important;
}
.addBtn button{
  background: #1E7FD9;
  border-color: #1E7FD9 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-right: 20px;
}

/* .subKpi-heading{
  word-wrap: break-word;
  width: 40rem;
} */


.input-error .p-inputnumber-input {
  border-color: red !important;
  border-width: 2px !important;
}
@media only screen and (max-width: 767px) {
  .sub_kpi_toolbar button{
    font-size: 14px;
    width: 80px !important;
  }
}
@media only screen and (max-width: 568px) {
  .sub_kpi_toolbar .endToolbar{
    gap: 0.5rem !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
  }
  .sub_kpi_toolbar button{
    font-size: 12px;
    width: 60px !important;
  }
}
.kpi_tabile_res .subKpiTable tr td textarea{
  min-height: 80px !important;
}
