.endtoolbar-button {
    border: none;
    white-space: nowrap;
    /*Prevent text from wrapping */
}

.endToolbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.required-marker {
    color: red;
    margin-left: 5px;
}

.p-button-danger {
    text-align: center;
    display: block;
}

.pi-info-circle {
    color: cornflowerblue;
    font-size: 1.5rem;
    text-align: center;
    display: block;
    cursor: pointer;
    font-size: 1.5rem;
}

.align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.text-center {
    text-align: center;
}

.align-in-row {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
}

.invalid-input {
    border: 1px solid red;
}

.error-message {
    font-size: 12px;
    color: red;
    margin-top: 2px;
}

/* -------------------Table Css --------------------------------------------------------*/


.container {
    padding: 20px;
}

.top-bar {
    margin-bottom: 10px;

}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    /* add padding-right to make room for the icon */
    font-size: 16px;
    box-sizing: border-box;
}

.search-icon {
    position: absolute;
    right: 10px;
    font-size: 2rem;
    color: #999999
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    text-align: left;
    padding: 10px;
}

.col-30 {
    width: 30%;
}

.col-70 {
    width: 70%;
}

.action-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: rgb(123, 69, 217);
    /* Button background color */
    color: #ffffff;
    /* Button text color */
    border: none;
    border-radius: 4px;
    font-size: 16px;
}

.settings-icon {
    margin-right: 8px;
}

.arrow-icon {
    margin-left: 4px;
}

.overley-item:hover {
    cursor: pointer;
    font-weight: bold;
}


/*---------------------------------------------------------------------*/


.option_kpi_container .search-container i{
    font-size: 1rem;
    right: 15px;
    top: 11px;
}